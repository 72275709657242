<template>
  <div class="survey-order w-100 text-center">
    <h4 class="mb-2 text-12">
      Bạn thấy quá trình mua hàng của bạn có dễ dàng không? <br />
      Hãy đánh giá ngay bên dưới nhé.
    </h4>
    <div class="survey-order-content">
      <div class="survey-order-content-rating">
        <b-form-rating style="border: none;" id="rating-7" stars="7" v-model="params.score" variant="warning" size="lg"></b-form-rating>
      </div>
      <div class="survey-order-content-txt">
        <p class="text">Rất khó khăn</p>
        <p class="text">Bình thường</p>
        <p class="text">Rất dễ dàng</p>
      </div>
    </div>
    <b-form-input
      v-model="params.content"
      type="text"
      placeholder="Góp ý khác" class="mb-2 text-14" style="height: 46px; padding-left: 26px;"
    />
    <small class="text font-italic font-weight-norma"
      >Hãy góp ý cho chúng tôi để trải nghiệm mua hàng của bạn tốt hơn nhé
    </small>
    <div class="row pt-1">
      <div class="col-12">
        <div class="w-100 pt-1 pb-2 text-center">
          <b-button
            variant="primary"
            class="style-button"
            @click="handelSubmit()"
            >HOÀN THÀNH</b-button
          >
        </div>
      </div>
      <div class="col-6">
        <b-button
          variant="outline-danger"
          class="style-button-chat"
          @click="
          gotoCSKH()
          "
          >Chat với CSKH</b-button
        >
      </div>
      <div class="col-6">
        <b-button
          variant="outline-danger"
          class="style-button-contact"
          @click="$bvModal.show('modal-phone')"
        >
          <span class="font-weight-btn"> Liên hệ CSKH</span> <br />
          087 7087087
        </b-button>
      </div>
      <b-modal
        hide-header
        body-class="p-2"
        title-class="text-primary"
        hide-footer
        id="modal-phone"
        content-class="your-class"
      >
        <b-button class="mt-1 d-flex align-items-center style-btn" block>
          <a href=" tel:087 7087087">
            <span class="mr-2">
              <img
                loading="lazy"
                src="@/assets/images/contact-phone.png"
                alt="Hotline ITEL"
              />
            </span>
            <span class="text-contact"> Gọi 087 7087087</span>
          </a>
        </b-button>
        <b-button
          class="mt-2 style-btn text-contact"
          block
          @click="$bvModal.hide('modal-phone')"
          >Hủy</b-button
        >
      </b-modal>
    </div>
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
import { BFormInput, BButton, BFormRating } from "bootstrap-vue";
export default {
  props: {
    phone: {
      type: String,
      default: localStorage.getItem('data'),
    },
    orderId: {
      type: String,
      default: "",
    },
  },
  components: {
    BButton,
    BFormInput,
    BFormRating,
  },
  data() {
    return {
      contribute: "",
      params: {
        phone: "",
        sourceType: "",
        productType: "",
        orderId: "",
        score: null,
        content: "",
      },
    };
  },
  created() {
    this.params = {
      ...this.params,
      sourceType: this.getBrowser() ? "Mobile" : "WebPC",
      productType:
        this.$router.history.current.name === 'club/voucher-detail' ? 'Voucher' :
        this.$router.history.current.name === "mua-hang-hoan-thanh"
          ? "Sim"
          : "Device",
    };
  },
  methods: {
    getBrowser() {
      const ua = navigator.userAgent;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          ua
        );
      return isMobile;
    },
    async handelSubmit() {
      this.params = {
        ...this.params,
        phone: this.phone,
        orderId: this.orderId,
      };
      if(this.params.score == null  && this.params.content === "") {
       this.$router.push("/");
        return  false
      }else if (this.params.score == null  && this.params.content.length >0 ) {

         this.$toast.error("Vui lòng chọn vào 1 trong 7 icon để đánh giá cho iTel nhé!",
          { icon: true, closeButton: "button" }
        );
        return false
      }else {

      }
      useJwt.post("createSurveyCes", this.params).then(result => {
        if( result.status === 200) {
          this.$toast.success("iTel cảm ơn bạn vì đã dành thời gian thực hiện khảo sát này!",
            { icon: true, closeButton: "button" }
          )
        }
        this.$router.push("/");
      }).catch(err => {
        this.$router.push("/");
      })
       
    },
    gotoCSKH(){ 
      cslw("open-widget")
    }
  },
};
</script>
<style  lang="scss" scoped>
.survey-order-content-txt {
  justify-content: space-between;
  margin: 0 7%;
  display: flex;
  padding-bottom: 30px;
}
.survey-order-content-rating {
  margin: 0 7%;;
}
.style-button-contact {
  width: 100%;
  border-radius: 27px !important;
  font-size: 16px;
  font-weight: 500;
}
.font-weight-btn {
  font-weight: 300;
}
.style-button-chat {
  width: 100%;
  border-radius: 27px !important;
  font-size: 16px;
  font-weight: 500;
}
.style-button {
  width: 100%;
  border-radius: 27px !important;
  font-size: 18px;
  font-weight: 600;
}
.text-14 {
  font-size: 14px;
}
.survey-order {
  font-size: 16px;
  padding-top: 28px;
  //   padding-bottom: 21px;
}
.style-btn {
  background: #fff !important;
}
.text-contact {
  color: #007bff !important;
  font-size: 18px;
}

@media screen and (max-width: 460px) {
  .survey-order-content-rating,
  .survey-order-content-txt {
    margin: 0;
  }
}
</style>

