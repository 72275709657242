<template>
  <div class="b2c-page-bg">
    <div v-if="step != 4" class="b2c-o-h">
      <validation-observer ref="infoValid">
        <div class="fs-sp"></div>
        <div>
          <div class="b2c-pc-header">
            <h2 class="w-100">GIỎ HÀNG & THANH TOÁN</h2>
            <div class="nav2 d-none d-md-block"></div>
          </div>
          <div v-if="!cart || cart.length == 0" class="b2c-pc-pack-content text-center text-middle">
            <div class="img-emtpty">
              <svg width="121" height="141" viewBox="0 0 121 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M90.8344 125.615C86.785 125.615 83.4897 129.027 83.4897 133.199C83.4897 137.4 86.785 140.783 90.8344 140.783C94.8838 140.783 98.1791 137.371 98.1791 133.199C98.207 128.998 94.9117 125.615 90.8344 125.615Z"
                  fill="url(#paint0_linear_16858_2376)" />
                <path
                  d="M55.0602 125.615C51.0108 125.615 47.7154 129.027 47.7154 133.199C47.7154 137.4 51.0108 140.783 55.0602 140.783C59.1095 140.783 62.4049 137.371 62.4049 133.199C62.4049 128.998 59.1095 125.615 55.0602 125.615Z"
                  fill="url(#paint1_linear_16858_2376)" />
                <path
                  d="M118.705 65.5671C116.946 63.1426 114.014 61.7612 110.69 61.7612H35.2044C33.3612 61.7612 31.6297 62.2123 30.1496 63.0017L26.1282 49.0468C25.8489 48.1165 25.2345 47.3271 24.3967 46.8478L5.93718 36.3323C4.17779 35.3174 1.94366 35.9658 0.938296 37.7419C-0.0670658 39.518 0.575248 41.8015 2.33463 42.7882L19.4816 52.5425L23.5031 66.4974L35.6233 107.347L37.2151 112.816C39.0024 118.708 45.2021 123.304 51.2901 123.304H100.665C102.703 123.304 104.351 121.64 104.351 119.582C104.351 117.524 102.703 115.861 100.665 115.861H51.3181C48.665 115.861 45.5931 113.69 44.504 111.209C45.7327 111.576 46.9894 111.773 48.2741 111.773H97.6485C103.737 111.773 109.936 107.178 111.724 101.286L119.962 74.3629C120.939 71.2054 120.465 67.9916 118.705 65.5671Z"
                  fill="url(#paint2_linear_16858_2376)" />
                <path d="M58.4785 14.8145L48.0704 25.3184L58.4756 35.8253L68.8837 25.3214L58.4785 14.8145Z"
                  fill="#FF6702" />
                <path d="M105.066 34.7548L93.595 44.0628L102.815 55.6423L114.286 46.3344L105.066 34.7548Z"
                  fill="#47BBDB" />
                <path d="M50.669 39.2919L36.6875 43.9332L41.2852 58.0474L55.2667 53.4061L50.669 39.2919Z"
                  fill="#47BBDB" />
                <path d="M82.8671 43.4258L77.2132 53.1186L86.8149 58.8261L92.4688 49.1332L82.8671 43.4258Z"
                  fill="#FF6702" />
                <path d="M73.2618 34.1325L62.85 38.1393L66.819 48.6498L77.2308 44.6431L73.2618 34.1325Z" fill="#F9AB23" />
                <path d="M91.9621 25.2609L86.0272 32.2671L92.9676 38.2583L98.9025 31.2521L91.9621 25.2609Z"
                  fill="#F9AB23" />
                <path d="M64.4033 0.962127L58.4684 7.96832L65.4088 13.9595L71.3437 6.95335L64.4033 0.962127Z"
                  fill="#F9AB23" />
                <path d="M94.61 11.223L91.201 19.7751L99.6728 23.2164L103.082 14.6643L94.61 11.223Z" fill="#FF6702" />
                <path d="M38.5279 25.3266L33.2313 32.8362L40.6703 38.183L45.9668 30.6734L38.5279 25.3266Z"
                  fill="#F9AB23" />
                <path d="M76.4452 16.5765L72.8417 25.0475L81.2331 28.6852L84.8366 20.2142L76.4452 16.5765Z"
                  fill="#47BBDB" />
                <defs>
                  <linearGradient id="paint0_linear_16858_2376" x1="60.0504" y1="196.752" x2="122.177" y2="70.9321"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F10000" />
                    <stop offset="1" stop-color="#FF3783" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_16858_2376" x1="31.1633" y1="182.488" x2="93.2896" y2="56.6684"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F10000" />
                    <stop offset="1" stop-color="#FF3783" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_16858_2376" x1="8.09159" y1="171.096" x2="70.2177" y2="45.2763"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F10000" />
                    <stop offset="0.2921" stop-color="#F10301" />
                    <stop offset="0.4693" stop-color="#F20B06" />
                    <stop offset="0.6157" stop-color="#F4190D" />
                    <stop offset="0.7455" stop-color="#F72E17" />
                    <stop offset="0.8642" stop-color="#FA4824" />
                    <stop offset="0.9733" stop-color="#FE6834" />
                    <stop offset="1" stop-color="#FF7139" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div>
              <label class="lbl-text-empty mt-2"> Không có sản phẩm nào trong giỏ hàng</label>
            </div>
            <div>
              <div class="col-12 text-center pt-2 mt-2">
                <button class="btn btn-primary btn-back mr-1" @click="gotoHome()">Quay về trang chủ</button>
              </div>
            </div>
            <div>
              <label class="cart-empty-help-lbl mt-3"> Quý Khách cần bất kỳ sự trợ giúp nào,
                vui lòng liên hệ <span class="phone-number">087 7087087</span>
              </label>
            </div>
          </div>
          <div v-else class="b2c-pc-pack-content">
            <div class="row">
              <div class="col-xl-6 col-lg-12 b2c-pc-payment">
                <h3 class="mb-1">Đơn hàng của bạn</h3>
                <div class="cart" v-for="(p, stt) in cart.filter(i => i.product_type != 100)" :key="stt">
                  <div class="image">
                    <img :src="p.images[0].image_url" class="image-product" alt="">
                  </div>
                  <div class="info">
                    <div class="price_box">
                      <span class="price">{{ p.price | number }}đ</span><br />
                      <span class="base_price">{{ p.base_price | number }}đ</span>
                    </div>
                    <h2><span v-html="p.product_name"></span></h2>
                    <!--<div class="features" v-if="p.config">
                      <span v-for="(c,stt) in p.config.filter(i=>i.on_top==1)" :key="stt">{{c.name}} <b>{{p.attributes[c.code]}}</b></span>
                    </div>-->
                    <div class="item-single-info" v-if="p.origin_type == 'baseus' && p.variants.length > 1">

                      <div class="item-single-info__title">{{ $t("DevicePage.Select") }} {{
                        p.nameOption.toLowerCase()
                      }}:
                      </div>
                      <!-- <img class="variant-color" role="button" :src="p.variants[p.selectIndex].image_url"
                          style="width: 25px !important;height: 25px; border-radius: 50%;" /> -->
                      <div class="item-single-info__name">
                        &nbsp;{{ p.variants[p.selectIndex].title }}
                      </div>
                    </div>
                    <div class="features row" v-else-if="p.origin_type != 'baseus'">
                      <div class="col-12 pb-1" v-for="(c, stt) in p.variants" :key="stt">
                        <div class="row" v-if="c.variant_type == 'color'">
                          <div class="float-left color-text">{{ c.variant_name }}</div>
                          <div class="variant-color float-left" :style="'background:' + c.selected.value"></div>
                        </div>
                        <div class="row" v-else>{{ c.variant_name }} <b>{{ c.selected.value }}</b></div>
                      </div>
                    </div>
                    <div class="quantity">
                      <p>Số lượng</p>
                      <feather-icon size="20" icon="MinusCircleIcon" role="button" @click="changeAmount(p, -1)" />
                      <div class="amount">{{ p.amount }}</div>
                      <feather-icon size="20" icon="PlusCircleIcon" role="button" @click="changeAmount(p, 1)" />
                    </div>
                  </div>
                  <span class="remove" role="button" @click="removeProduct(p)">
                    <feather-icon size="20" icon="TrashIcon" /> Xoá sản phẩm
                  </span>
                </div>
                <div class="cart" v-for="p in cart.filter(i => i.product_type == 100)" :key="p.Phone">
                  <div class="image">
                    <img :src="require('@/assets/images/sim_itel.png')" alt="">
                  </div>
                  <div class="info">
                    <div class="price_box">
                      <span class="price">{{ p.PackPrice | number }}đ</span><br />
                      <span class="base_price"></span>
                    </div>
                    <h2>SIM DATA ITEL</h2>
                    <div class="features">
                      <span>Số thuê bao <b>{{ f087(p.Phone) }}</b></span>
                      <span>Gói cước <b>{{ p.Pack }}</b></span>
                    </div>
                    <div class="quantity disabled">
                      <p>Số lượng</p>
                      <feather-icon size="20" icon="MinusCircleIcon" />
                      <div class="amount">1</div>
                      <feather-icon size="20" icon="PlusCircleIcon" />
                    </div>
                  </div>
                  <span class="remove" role="button" @click="removeProduct(p)">
                    <feather-icon size="20" icon="TrashIcon" /> Xoá sản phẩm
                  </span>
                </div>
              </div>
              <div class="b2c-pc-info col-xl-6 col-lg-12">
                <b-card class="p-0" body-class="p-0">
                  <b-card-header>Thông tin người nhận</b-card-header>
                  <b-card-text>
                    <!--<validation-observer ref="infoValid">-->
                    <div class="row p-2">
                      <div class="col-md-6">
                        <validation-provider #default="{ errors }" rules="required|min:3|space">
                          <label class="d-block">Họ và tên <span class="text-primary">*</span></label>
                          <b-form-input maxlength="35" size="sm1" v-model="cart_info.fullName"
                            :state="errors.length > 0 ? false : null" />
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập đầy đủ họ và tên người
                            nhận</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-6">
                        <validation-provider #default="{ errors }" rules="required|min:10">
                          <label class="d-block">Điện thoại liên hệ <span class="text-primary">*</span></label>
                          <b-form-input size="sm1" type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                            maxlength="10" v-model.trim="cart_info.ContactPhone"
                            :state="errors.length > 0 ? false : null" />
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập số điện thoại liên
                            hệ</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-12">
                        <validation-provider #default="{ errors }" rules="email">
                          <label class="d-block">Email</label>
                          <b-form-input size="sm1" maxlength="40" v-model.trim="cart_info.email"
                            :state="errors.length > 0 ? false : null" />
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập chính xác email</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="w-100 b2c-cart-info-title border">
                      <span>Giao hàng tận nơi</span>
                    </div>
                    <div class="row p-2" v-if="!isLoading && cart_info.shipment_type == 'home'">
                      <div class="col-md-12">
                        <validation-provider #default="{ errors }" rules="required|min:2">
                          <label class="d-block">Địa chỉ <span class="text-primary">*</span></label>
                          <b-form-input size="sm1" placeholder="Số nhà, tên đường, tên thôn/ấp/khu" maxlength="100"
                            v-model="cart_info.addr" :state="errors.length > 0 ? false : null" />
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng điền chi tiết số nhà, tên đường,
                            thôn/ấp/khu</small>
                        </validation-provider>
                      </div>
                      <div class="col-6">
                        <validation-provider #default="{ errors }" rules="required|min0">
                          <label class="d-block">Tỉnh/TP <span class="text-primary">*</span></label>
                          <v-select @input="onCityChange" label="text" :class="errors.length > 0 ? 'is-invalid' : ''"
                            v-model="CityId" :filter="fuseSearch" :options="Cities" :reduce="CityId => CityId.id">
                          </v-select>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập Tỉnh/TP</small>
                        </validation-provider>
                      </div>
                      <div class="col-6">
                        <validation-provider #default="{ errors }" rules="required|min0">
                          <label class="d-block">Quận/Huyện <span class="text-primary">*</span></label>
                          <v-select :disabled="!(CityId > 0)" :class="errors.length > 0 ? 'is-invalid' : ''"
                            :filter="fuseSearch" label="text" v-model="DistrictId" :options="Districts"
                            :reduce="c => c.id"></v-select>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập Quận/Huyện</small>
                        </validation-provider>
                      </div>
                      <div class="col-6">
                        <validation-provider #default="{ errors }" rules="required|min0">
                          <label class="d-block">Phường/Xã <span class="text-primary">*</span></label>
                          <v-select :disabled="!(DistrictId > 0)" :class="errors.length > 0 ? 'is-invalid' : ''"
                            :filter="fuseSearch" label="text" v-model="WardId" :options="Wards" :reduce="c => c.id">
                          </v-select>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập Phường/Xã</small>
                        </validation-provider>
                      </div>
                      <div class="col-6">
                        <validation-provider #default="{ errors }" rules="">
                          <label class="d-block">Ấp/Khu</label>
                          <v-select :disabled="!(WardId > 0)" :filter="fuseSearch" label="text" v-model="HamletId"
                            :options="Hamlets" :reduce="c => c.id"></v-select>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập Ấp/Khu</small>
                        </validation-provider>
                      </div>
                      <div class="col-12 mt-1 hidden" v-if="hasExpressShip">
                        <div class="d-flex space-between delivery-type">
                          <div class="info-delivery">
                            <div class="info-delivery__title">Giao hàng hỏa tốc</div>
                            <div class="info-delivery__sub-title">{{ expressShipInfo.deleveryTime }}</div>
                          </div>
                          <div class="check-box mr-3" @click="onClickExpress()">
                            <img v-if="isExpress" alt="checkbox" src="../../assets/images/check-circle-red.png" />
                            <img v-else alt="checkbox" src="../../assets/images/circleblank.png" />
                          </div>

                        </div>
                        <div class="border-bt"></div>
                      </div>
                      <div class="col-12 hidden">
                        <div class="d-flex space-between delivery-type">
                          <div class="info-delivery">
                            <div class="info-delivery__title">Giao hàng nhanh</div>
                            <div class="info-delivery__sub-title">Nhận hàng từ 1 tới 3 ngày kể từ thời điểm đặt đơn hàng
                            </div>
                          </div>
                          <div class="check-box mr-3" @click="onClickFast()">
                            <img v-if="isFast" alt="checkbox" src="../../assets/images/check-circle-red.png" />
                            <img v-else alt="checkbox" src="@/assets/images/circleblank.png" />
                          </div>
                        </div>
                        <div class="border-bt"></div>
                      </div>
                    </div>
                  </b-card-text>
                  <b-row class="pt-2 pl-3 pr-3" :class="stt == 0 ? 'border-top mt-3' : ''"
                    v-for="(p, stt) in cart.filter(m => m.product_type != 100)" :key="stt">
                    <b-col cols="6" class="h2"><span v-html="p.product_name"></span></b-col>
                    <b-col cols="1" class="h2 text-right">{{ p.amount }}</b-col>
                    <b-col cols="5" class="h2 text-right">{{ p.price | number }}đ</b-col>
                  </b-row>
                  <b-row class="pt-2 pl-3 pr-3" v-for="(p, stt) in cart.filter(m => m.product_type == 100)" :key="stt">
                    <b-col cols="6" class="h2">SIM DATA ITEL</b-col>
                    <b-col cols="1" class="h2 text-right">1</b-col>
                    <b-col cols="5" class="h2 text-right">{{ p.PackPrice | number }}đ</b-col>
                  </b-row>

                  <div class="w-100 b2c-cart-info-title border">
                    <span>Chọn hình thức thanh toán</span>
                  </div>
                  <b-row>
                    <b-col cols="12" class="p-3">
                      <div class="bg-white">
                        <div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox"
                          @click="ChangePaymentMethod('VNPAY')">
                          <div class="d-flex align-items-center ">
                            <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/VNPay.svg')"
                              alt="logoVNPay" />
                            <span class="inputText mr-2">Thanh toán qua </span>
                            <b-img width="71px" height="71px" :src="require('@/assets/images/b2c/logoVNPay.svg')"
                              alt="logoVNPay" />
                          </div>
                          <b-form-checkbox value="VNPAY" v-model="paymentMethod" class="vs-checkbox-con" disabled>
                          </b-form-checkbox>
                        </div>
                        <div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox"
                          @click="ChangePaymentMethod('BANK')">
                          <div class="d-flex align-items-center ">
                            <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/momo.svg')"
                              alt="logoVNPay" />
                            <span class="inputText">Qua thẻ ATM (có Internet Banking)</span>
                          </div>
                          <b-form-checkbox value="BANK" v-model="paymentMethod" disabled>
                          </b-form-checkbox>
                        </div>
                        <div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox"
                          @click="ChangePaymentMethod('INTCARD')">
                          <div class="d-flex align-items-center ">
                            <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/momo.svg')"
                              alt="logoVNPay" />
                            <span class="inputText mr-2">Qua thẻ thanh toán quốc tế</span>
                            <b-img width="100px" height="43px" :src="require('@/assets/images/b2c/ListCard.png')"
                              alt="IntCard" />
                          </div>
                          <b-form-checkbox value="INTCARD" v-model="paymentMethod" disabled>
                          </b-form-checkbox>

                        </div>
                        <div style="margin-bottom: 2%" class="borderPaymentBox">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center ">
                              <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/ViDienTu.svg')"
                                alt="logoVNPay" />
                              <span class="inputText"> Thanh toán với ví điện tử</span>
                            </div>
                            <b-form-checkbox v-model="isZaloMono" disabled>
                            </b-form-checkbox>
                          </div>
                          <div>
                            <button style="margin-left: 3%" class="buttonZaloMomo" @click="ChangePaymentMethod('ZALO')"
                              :class="paymentMethod === 'ZALO' ? 'buttonZaloMomoActive' : ''">
                              <b-img width="150px" height="42px" :src="require('@/assets/images/b2c/LogoZalo.png')"
                                alt="logoZalo" class="zaloLogo" />
                            </button>
                            <button class="buttonZaloMomo" @click="ChangePaymentMethod('MOMO')"
                              :class="paymentMethod === 'MOMO' ? 'buttonZaloMomoActive' : ''">
                              <b-img height="57px" width="57px" :src="require('@/assets/images/b2c/LoloMomo.png')"
                                alt="logoMomo" class="momoLogo" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="border-top pt-2 pl-3 pr-3">
                    <b-col cols="6" class="h2">Phí vận chuyển</b-col>
                    <b-col cols="6" class="h2 text-right">
                      <p v-if="cart_info.ghtk_fee != undefined || (isExpress && cart_info.shipment_type == 'home')"
                        class="b2c-ghtk-fee">{{ (isExpress && cart_info.shipment_type == 'home') ?
                          formatNumber(expressShipInfo.ahamoveFee) : (isLoadingFee ? 0 :
                            formatNumber(cart_info.ghtk_fee))
                        }}đ</p>{{ (isExpress && cart_info.shipment_type == 'home') ?
  formatNumber(expressShipInfo.lastFee) : formatNumber(getShipmentFee())
}}đ
                    </b-col>
                    <b-col cols="6" class="h1 pt-1 mb-1">Tổng tiền</b-col>
                    <b-col cols="6" class="huge-text pt-1 text-primary text-right mb-1">{{ getTotalPrice() -totalSalePrice|number}}đ
                      <p v-if="isStatusUrbox" class="b2c-ghtk-fee" style="color: #5E5873;">{{ totalSalePrice | number }}đ</p>
                    </b-col>
                    <b-col cols="12" class="d-flex mb-1" v-if="!isWholeSale">
                      <b-form-input size="sm1" placeholder="Nhập mã ưu đãi" style="width: 85%;"
                                    v-model="promotionCode" @update="handlePromotionCode()"/>
                      <b-button :disabled="promotionCode === null || promotionCode === ''"
                                @click="getStatusPromotionCode()" class="b2c__btn"
                                :style="promotionCode === null || promotionCode === '' ? '' : 'background: #ed1f24 !important;'">
                        Áp dụng
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <p v-if="isStatusUrbox" style="color: blue; font-size: 16px;">Ưu đãi dành cho khách hàng có mã giảm giá của UrBox</p>
                    </b-col>
                  </b-row>
                  <b-row hidden>
                    <div class="p-2 pl-4 pr-4 w-100">
                      <div class="border d-block bg-white rounded text-center arrow-icon-end">
                        <span>Áp dụng ưu đãi để được giảm giá</span>
                      </div>
                    </div>
                  </b-row>
                  <button v-if="!isOnIframe" @click="onPayment()" class="b2c-search-button b2c-search-button-red">Thanh
                    toán</button>
                  <button v-if="isOnIframe" @click="onOrder()" class="b2c-search-button b2c-search-button-red">Tạo
                    đơn</button>
                  <b-row>
                    <div class="pl-4 pr-4 w-100 mb-4">
                      <div class="d-block bg-white text-center" style="line-height:1.5">
                        Bằng cách bấm thanh toán, bạn đồng ý với <span @click="gotoPolicy()"
                          class="text-primary text-decoration-underline">Chính sách mua hàng</span> của
                        iTel
                      </div>
                    </div>
                  </b-row>
                  </b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
    <div class="p-2" v-else-if="step == 4">
      <div v-if="VnPayResponse" class="row">
        <b-card class="complete">
          <div class="col-md-12">
            <div class="pb-3 pt-3 w-100 text-center" v-if="VnPayResponse.vnp_ResponseCode == '00'">
              <h2 class="huge-text pb-3">
                <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                    stroke="#40E108" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <br><br>
                Đặt hàng thành công
              </h2>
              <p class="text result-phone">Bạn sẽ nhận được tin nhắn thông tin đặt hàng về số điện thoại</p>
            </div>
            <div class="pb-3 pt-3 w-100 text-center border-bottom" v-else>
              <h2 class="huge-text">
                <svg width="127" height="127" viewBox="0 0 127 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M81.8216 44.75L45.1787 81.3929M45.1787 44.75L81.8216 81.3929M124.572 63.0714C124.572 96.8002 97.229 124.143 63.5001 124.143C29.7713 124.143 2.42871 96.8002 2.42871 63.0714C2.42871 29.3426 29.7713 2 63.5001 2C97.229 2 124.572 29.3426 124.572 63.0714Z"
                    stroke="#ED1F24" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <br><br>Thanh toán thất bại
              </h2>
            </div>
            <div v-if="VnPayResponse.vnp_ResponseCode == '00'" class="p-0">
              <div class="detail-order p-2">
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng
                  </b-col>
                  <b-col cols="6" class="pl-0 h1 text-right text-primary">{{ VnPayResponse.vnp_TxnRef }}</b-col>
                </b-row>
                <b-row class="pt-0">
                  <b-col cols="12" class="text text-primary hidden">Bạn lưu ý ghi nhớ mã đơn hàng để phục vụ kích hoạt
                    SIM</b-col>
                </b-row>
                <b-row class="pt-0 pb-1 border-bottom">
                  <b-col cols="6" class="h2">Đã thanh toán</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{ VnPayResponse.vnp_Amount / 100 | number }}đ
                  </b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="h2 b2c-price-texts">Người mua hàng: <span class="h2 text-primary">{{
                    OrderInfo.FullName
                  }}</span></b-col>
                  <b-col cols="12" class="h2 b2c-price-texts">Số điện thoại liên hệ: <span class="h2 text-primary">{{
                    OrderInfo.ContactPhone
                  }}</span></b-col>
                  <b-col cols="12" class=" h2 b2c-price-texts">Địa chỉ nhận hàng: <span class="h2 text-primary">{{
                    OrderInfo.Addr
                  }}</span></b-col>
                </b-row>
              </div>
              <div class="w-100 pt-1  text-center">
                <p class="b2c-rate-text  h6 font-weight-normal">Xem thông tin đơn hàng <a
                    :href="'/shipment/' + VnPayResponse.vnp_TxnRef"><b class="text-primary ">tại
                      đây</b></a></p>
                <!-- <p class="b2c-rate-text">Bạn cảm thấy quá trình mua hàng có dễ dàng thuận tiện không?</p>
              <div class="p-3 text-center"><star-rating @rating-selected="updateRate" active-color="#FFCB45" :show-rating="false" /></div> -->
                <!-- khảo sát ces -->
                <SurveyCES :phone="OrderInfo.ContactPhone" :orderId="VnPayResponse.vnp_TxnRef" />
                <!-- <router-link class="btn btn-primary mr-1" to="/mua-hang">Giao dịch mới</router-link><router-link class="btn btn-secondary mr-1" to="/">Về trang chủ</router-link> -->
              </div>
            </div>
            <b-card v-else class="p-0">
              <b-card-text>
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{ VnPayResponse.vnp_TxnRef }}</b-col>
                </b-row>
                <b-row class="pt-1 pb-1 border-bottom">
                  <b-col cols="4" class="h2">Tình trạng</b-col>
                  <b-col cols="8" class="h2 text-right text-primary">Thanh toán thất bại</b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="h2">Thông báo chi tiết</b-col>
                  <b-col cols="12" class="b2c-price-texts">
                    {{ vnPayErrors.find(e => e.id == VnPayResponse.vnp_ResponseCode).text }}</b-col>
                </b-row>
              </b-card-text>
            </b-card>
            <div class="row pt-3" v-if="VnPayResponse.vnp_ResponseCode != '00'">
              <div class="col-12">
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button variant="primary" @click="chonThanhToanLai()" class="mr-1">Thanh toán lại</b-button>
                </div>
              </div>
              <div class="col-12">
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="redirectToSupport" variant="secondary">Liên hệ hỗ trợ</b-button>
                </div>
              </div>
            </div>
          </div>

        </b-card>
      </div>
      <!-- Success Failure  MOMO-->
      <div v-if="MoMoResponse" class="row">
        <b-card class="complete">
          <div class="col-md-12">
            <div class="pb-3 pt-3 w-100 text-center border-bottom" v-if="MoMoResponse.resultCode == 0">
              <h2 class="huge-text pb-3">
                <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                    stroke="#40E108" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <br><br>
                Đặt hàng thành công
              </h2>
              <p class="text" v-if="OrderInfo.ContactPhone">Quý Khách sẽ nhận được tin nhắn thông tin đặt hàng về số
                điện thoại</p>
            </div>
            <div v-if="MoMoResponse.resultCode == 0" class="p-0">
              <div class="detail-order p-2">
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng
                  </b-col>
                  <b-col cols="6" class="pl-0 h1 text-right text-primary">{{ MoMoResponse.orderId }}</b-col>
                </b-row>
                <b-row class="pt-0">
                  <b-col cols="12" class="text text-primary hidden">Bạn lưu ý ghi nhớ mã đơn hàng để phục vụ kích hoạt
                    SIM</b-col>
                </b-row>
                <b-row class="pt-0 pb-1 border-bottom">
                  <b-col cols="6" class="h2">Đã thanh toán</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{ MoMoResponse.amount }}đ</b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="b2c-price-texts">Người mua hàng: <span class="h2 text-primary">{{
                    OrderInfo.FullName
                  }}</span></b-col>
                  <!-- <b-col cols="12" class="h2 text-primary"></b-col> -->
                  <b-col cols="12" v-if="OrderInfo.Phones" class="b2c-price-texts ">Số iTel đã đặt hàng: <span
                      class="h2 text-primary">{{ OrderInfo.Phones }}</span></b-col>
                  <!-- <b-col cols="12" v-if="OrderInfo.Phones" class="h2 "></b-col> -->
                  <b-col cols="12" class="b2c-price-texts">Số điện thoại liên hệ: <span class="h2 text-primary">{{
                    OrderInfo.ContactPhone
                  }}</span></b-col>
                  <!-- <b-col cols="12" class="h2 text-primary"></b-col> -->
                  <b-col cols="12" v-if="OrderInfo.hassim == 0" class="b2c-price-texts">Email: <span
                      class="h2 text-primary">{{ OrderInfo.Email }}</span></b-col>

                  <b-col cols="12" v-if="OrderInfo.hassim > 0" class="b2c-price-texts">Địa chỉ nhận hàng: <span
                      class="h2 text-primary">{{ OrderInfo.Addr }}</span></b-col>
                  <!-- <b-col cols="12" v-if="OrderInfo.hassim > 0" class="h2 text-primary"></b-col> -->
                </b-row>
              </div>
              <div class="w-100 pt-1 pb-2 text-center">
                <!-- Chi tiết đơn hàng -->
                <p class="b2c-rate-text  h6 font-weight-normal">Xem thông tin đơn hàng <a
                    :href="urlCurrent + '/shipment/' + MoMoResponse.orderId"><b class="text-primary ">tại
                      đây</b></a></p>
                <!-- khảo sát ces -->
                <SurveyCES :phone="OrderInfo.ContactPhone" :orderId="MoMoResponse.orderId" />
                <!-- <p class="b2c-rate-text">Bạn cảm thấy quá trình mua hàng có dễ dàng thuận tiện không?</p>
                <div class="p-3 text-center"><star-rating @rating-selected="updateRate" active-color="#FFCB45" :show-rating="false" /></div> -->
                <!-- <router-link class="btn btn-primary mr-1" to="/mua-hang">Giao dịch mới</router-link><router-link class="btn btn-secondary mr-1" to="/">Về trang chủ</router-link> -->
              </div>
            </div>

            <div class="row pt-3" v-if="MoMoResponse.resultCode != 0">
              <div class="col-12">
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="chonThanhToanLai()" variant="primary" class="">Thanh toán lại</b-button>
                </div>
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="redirectToSupport" variant="secondary">Liên hệ hỗ trợ</b-button>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <!-- Success Failure  ZALO PAY-->

      <div v-if="ZaloResponse" class="row">
        <b-card class="complete">
          <div class="col-md-12">
            <div class="pb-3 pt-3 w-100 text-center border-bottom" v-if="ZaloResponse.status == 1">
              <h2 class="huge-text pb-3">
                <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                    stroke="#40E108" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <br><br>
                Đặt hàng thành công
              </h2>
              <p class="text" v-if="OrderInfo.ContactPhone">Quý Khách sẽ nhận được tin nhắn thông tin đặt hàng về số
                điện thoại</p>
            </div>
            <div v-if="ZaloResponse.status == 1" class="p-0">
              <div class="detail-order p-2">
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng
                  </b-col>
                  <b-col cols="6" class="pl-0 h1 text-right text-primary">{{ ZaloResponse.apptransid }}</b-col>
                </b-row>
                <b-row class="pt-0">
                  <b-col cols="12" class="text text-primary hidden">Bạn lưu ý ghi nhớ mã đơn hàng để phục vụ kích hoạt
                    SIM</b-col>
                </b-row>
                <b-row class="pt-0 pb-1 border-bottom">
                  <b-col cols="6" class="h2">Đã thanh toán</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{ ZaloResponse.amount }}đ</b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="b2c-price-texts">Người mua hàng: <span class="h2 text-primary">{{
                    OrderInfo.FullName
                  }}</span></b-col>
                  <!-- <b-col cols="12" class="h2 text-primary"></b-col> -->
                  <b-col cols="12" v-if="OrderInfo.Phones" class="b2c-price-texts ">Số iTel đã đặt hàng: <span
                      class="h2 text-primary">{{ OrderInfo.Phones }}</span></b-col>
                  <!-- <b-col cols="12" v-if="OrderInfo.Phones" class="h2 "></b-col> -->
                  <b-col cols="12" class="b2c-price-texts">Số điện thoại liên hệ: <span class="h2 text-primary">{{
                    OrderInfo.ContactPhone
                  }}</span></b-col>
                  <!-- <b-col cols="12" class="h2 text-primary"></b-col> -->
                  <b-col cols="12" v-if="OrderInfo.hassim == 0" class="b2c-price-texts">Email: <span
                      class="h2 text-primary">{{ OrderInfo.Email }}</span></b-col>

                  <b-col cols="12" v-if="OrderInfo.hassim > 0" class="b2c-price-texts">Địa chỉ nhận hàng: <span
                      class="h2 text-primary">{{ OrderInfo.Addr }}</span></b-col>
                  <!-- <b-col cols="12" v-if="OrderInfo.hassim > 0" class="h2 text-primary"></b-col> -->
                </b-row>
              </div>
              <div class="w-100 pt-1 pb-2 text-center">
                <!-- Chi tiết đơn hàng -->
                <p class="b2c-rate-text  h6 font-weight-normal">Xem thông tin đơn hàng <a
                    :href="urlCurrent + '/shipment/' + ZaloResponse.apptransid"><b class="text-primary ">tại
                      đây</b></a></p>
                <!-- khảo sát ces -->
                <SurveyCES :phone="OrderInfo.ContactPhone" :orderId="ZaloResponse.apptransid" />
                <!-- <p class="b2c-rate-text">Bạn cảm thấy quá trình mua hàng có dễ dàng thuận tiện không?</p>
                <div class="p-3 text-center"><star-rating @rating-selected="updateRate" active-color="#FFCB45" :show-rating="false" /></div> -->
                <!-- <router-link class="btn btn-primary mr-1" to="/mua-hang">Giao dịch mới</router-link><router-link class="btn btn-secondary mr-1" to="/">Về trang chủ</router-link> -->
              </div>
            </div>

            <div class="row pt-3" v-if="ZaloResponse.status != 1">
              <div class="col-12">
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="chonThanhToanLai()" variant="primary" class="">Thanh toán lại</b-button>
                </div>
                <div class="w-100 pt-1 pb-2 text-center">
                  <b-button @click="redirectToSupport" variant="secondary">Liên hệ hỗ trợ</b-button>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <b-modal body-class="p-2" hide-footer id="modal-policy" title=" Chính sách mua hàng" centered>
      <b-row>
        <b-col cols="12">
          <p class="text text-left policy-popup" v-for="(p, stt) in listPolicys" :key="stt">{{ (stt + 1) }} <span
              v-html="p"></span></p>
          <p class="text text-left policy-popup">Xin chân thành cảm ơn.</p>
        </b-col>
        <b-col cols="12" class="border-top text-center pt-2 mt-1">
          <b-button @click="$bvModal.hide('modal-policy')" variant="primary" size="sm" class="btn-close-modal">Đóng
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal body-class="p-2" hide-footer id="modal-whole-sale" title="Thông báo!" centered>
      <b-row>
        <b-col cols="12">
          <p class="text text-left policy-popup" v-html="erroWhole">
          </p>
          <!-- <p class="text text-left policy-popup">
            Bạn vui lòng thực hiện lại nhé!
          </p> -->
        </b-col>
        <b-col cols="12" class="border-top text-center pt-2 mt-1">
          <!-- <b-button @click="$bvModal.hide('modal-whole-sale')" variant="primary" size="sm"
            class="btn-close-modal mr-2">Đóng
          </b-button> -->
          <b-button @click="wholeContinue()" variant="primary" size="sm" class="btn-close-modal ">Chọn thêm
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <div class="d-none">
      <b-modal id="bv-modal-example" hide-footer>
        <div class="d-block text-center">
          <h1 class="font-weight-bold mb-2" style="color: black; font-size: 20px;">THÔNG BÁO</h1>
          <p class="mb-3">Mã ưu đãi không đúng hoặc đã hết lượt sử dụng.<br> Vui lòng kiểm tra lại!</p>
        </div>
        <div class="d-flex justify-content-center">
          <b-button class="mb-2" @click="closePopup()" style="border: none !important; background: #ed1f24 !important; width: 100px; height: auto; border-radius: 2rem !important; font-size: 16px;">
            Đóng
          </b-button>
        </div>
      </b-modal>
    </div>
    <div class="d-none">
      <b-modal id="bv-modal-sim-data" hide-footer>
        <div class="d-block text-center">
          <h1 class="font-weight-bold mb-2" style="color: black; font-size: 20px;">THÔNG BÁO</h1>
          <p class="mb-3">Mã ưu đãi chỉ áp dụng cho đơn hàng SIM DATA<br>Vui lòng lựa chọn lại loại SIM để nhận ưu đãi!
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <b-button class="mb-2" @click="closePopup()"
          style="border: none !important; background: #ed1f24 !important; width: 100px; height: auto; border-radius: 2rem !important; font-size: 16px;">
            Đóng
          </b-button>
        </div>
      </b-modal>
    </div>
    <div class="d-none">
      <b-modal id="bv-modal-baseUS" hide-footer>
        <div class="d-block text-center">
          <h1 class="font-weight-bold mb-2" style="color: black; font-size: 20px;">THÔNG BÁO</h1>
          <p class="mb-3 pl-2 pr-2">Mã ưu đãi chỉ áp dụng cho sản phẩm BASEUS và Sim iTel.<br>Quý khách vui lòng kiểm tra lại!
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <b-button class="mb-2" @click="closePopup()"
          style="border: none !important; background: #ed1f24 !important; width: 100px; height: auto; border-radius: 2rem !important; font-size: 16px;">
            Đóng
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, email } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from "v-select2-component";
import {
  BCardHeader,
  BCard,
  VBTooltip,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BPagination,
  BCardText,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  TabsPlugin,
  BTabs,
  BTab,
} from "bootstrap-vue";
import SurveyCES from "@/components/SurveyCES.vue";
import { WebCam } from "vue-web-cam";
import Vue from "vue";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";
import Fuse from "fuse.js";
import { $ctx } from "@themeConfig";
import { v4 as uuidv4 } from "uuid";
import { formatNumber } from "@core/utils/utils";
import { OriginType, ProvinceIdConst } from "@/libs/acl/config";

let debounceHandle = null;

Vue.component("v-select", vSelect);

extend("msisdn", (value) => {
  const first4 = value.slice(0, 4);
  const first3 = value.slice(0, 3);
  if (first4 !== "8487" && first3 !== "087") {
    return false;
  } else if (
    (first4 === "8487" && value.length !== 11) ||
    (first3 === "087" && value.length !== 10)
  ) {
    return false;
  }
  return true;
});

extend("num", (value) => {
  return !/\D/.test(value);
});
extend("space", (value) => {
  return value.indexOf(" ") > 0;
});
extend("min0", (value) => {
  return value > 0;
});

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    BCardHeader,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    FeatherIcon,
    BFormCheckbox,
    BCardText,
    BPagination,
    "vue-web-cam": WebCam,
    VBTooltip,
    StarRating,
    Fuse,
    BImg,
    TabsPlugin,
    BTabs,
    BTab,
    SurveyCES,
  },
  data() {
    return {
      isUrbox: false,
      totalSalePrice: 0,
      isStatusUrbox: false,
      isSaleEvoucher: 0,
      promotionCode: '',
      numberExceptCollapse: true,
      storeCollapse: true,
      step: 1,
      NumOfPhone: 1,
      deviceId: null,
      isLoading: false,
      isLoadingFee: false,
      currentPhone: "",
      SearchString: "",
      Slug: "",
      required,
      min,
      email,
      totalRecords: 0,
      rows: [],
      paymentMethod: "VNPAY",
      isZaloMono: false,
      VnPayResponse: null,
      MoMoResponse: null,
      cart_info: {
        shipment_type: "home",
        data_hasSIM: true,
        ghtk_fee: 0,
        _track: "T_" + Math.random().toString(36).slice(2),
      },
      cart: [],
      DataPackages: [],
      years: [],
      Cities: [],
      Districts: [],
      Wards: [],
      Hamlets: [],
      CityId: 0,
      DistrictId: 0,
      WardId: 0,
      HamletId: 0,
      Stores: [],
      vnPayErrors: [
        { id: "00", text: "Giao dịch thành công" },
        {
          id: "07",
          text: "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).",
        },
        {
          id: "09",
          text: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.",
        },
        {
          id: "10",
          text: "Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần",
        },
        {
          id: "11",
          text: "Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.",
        },
        {
          id: "12",
          text: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.",
        },
        {
          id: "13",
          text: "Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.",
        },
        {
          id: "24",
          text: "Giao dịch không thành công do: Khách hàng hủy giao dịch",
        },
        {
          id: "51",
          text: "Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.",
        },
        {
          id: "65",
          text: "Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.",
        },
        { id: "75", text: "Ngân hàng thanh toán đang bảo trì." },
        {
          id: "79",
          text: "Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch",
        },
        {
          id: "99",
          text: "Lỗi thanh toán ngân hàng, quý khách vui lòng thử lại",
        },
      ],
      erroWhole:'Bạn ơi, đơn hàng mua sỉ chưa đạt yêu cầu. Hãy chọn thêm sản phẩm để thỏa mãn 2 điều kiện:   <br> 1. Giỏ hàng ≥ 05 sản phẩm   <br> 2. Tổng giá trị đơn hàng ≥ 5.250.000 VNĐ. <br>Bạn vui lòng thực hiện lại nhé!',
      OrderInfo: {},
      isExpress: false,
      isFast: true,
      hasExpressShip: false,
      expressShipInfo: {
        ahamoveFee: 0,
        lastFee: 0,
        deleveryTime: "",
      },
      urlCurrent: "",
      listPolicys: [],
      isWholeSale:false
    };
  },
  computed: {
    isOnIframe() {
      return this.$store.getters[`app/isOnIframe`];
    },
  },
  watch: {
    paymentMethod(newMethod, oldMethod) {
      if (newMethod === "ZALO" || newMethod === "MOMO") {
        this.isZaloMono = true;
      } else {
        this.isZaloMono = false
      }
    },
    $route(to, from) {
      this.step = 1;
      this.cart_info._track = "T_" + Math.random().toString(36).slice(2);
    },
    cart_info: {
      handler: function (o, n) { },
      deep: true,
    },
    CityId: function (i, v) {
      this.DistrictId = 0;
      if (this.CityId > 0) {
        this.loading(true);
        useJwt
          .post("getMasterData/GHTK_Districts", {
            columnFilters: { CityId: this.CityId },
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Districts = response.data.result;
            this.loading(false);
          });
      }
    },
    DistrictId: function (i, v) {
      this.WardId = 0;
      this.getShippingFee();
      if (this.DistrictId > 0) {
        this.loading(true);
        useJwt
          .post("getMasterData/GHTK_Wards", {
            columnFilters: { DistrictId: this.DistrictId },
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Wards = response.data.result;
            this.loading(false);
          });
      }
    },
    WardId: async function (i, v) {
      this.HamletId = 0;
      if (this.WardId > 0) {
        this.loading(true);
        await useJwt
          .post("getMasterData/GHTK_Hamlets", {
            columnFilters: { WardId: this.WardId },
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Hamlets = response.data.result;
            this.loading(false);
          });
      }
      this.isLoadingFee = true;
      this.getShippingFee();
      await useJwt
        .get(
          "ghtk/fee?province=" +
          this.CityId +
          "&district=" +
          this.DistrictId +
          "&ward=" +
          this.WardId
        )
        .then((response) => {
          this.cart_info = {
            ...this.cart_info,
            ghtk_fee: response.data.result.fee.fee,
          };
          this.isLoadingFee = false;
        })
        .catch((err) => {
          this.cart_info = {
            ...this.cart_info,
            ghtk_fee: 0,
          };
          this.isLoadingFee = false;
        });
    },
    "cart_info.fullName": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    "cart_info.addr": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    "cart_info.ContactPhone": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    step: function (newValue, oldValue) {
      localStorage.userTrackStep = newValue;
    },
  },
  created() {
    this.getStatusPromotionCode();
    this.cart_info.ContactPhone = localStorage.getItem('phoneWholeSale');
    this.urlCurrent = $ctx.CURRENT_URL;
    this.years.push({ id: 0, text: "Tất cả ngày sinh" });
    for (var i = 0; i < 50; i++) {
      this.years.push({ id: i + 1970, text: i + 1970 });
    }
    const cart_info = JSON.parse(sessionStorage.getItem("cart_info"));
    if (!!cart_info) {
      this.cart_info = cart_info;
    }
    const customLocation = JSON.parse(
      sessionStorage.getItem("customer_location")
    );
    //lấy tỉnh thành phố
    this.loading(true);
    if (!!cart_info?.CityId && !!customLocation?.Cities) {
      this.CityId = cart_info.CityId;
      this.Cities = customLocation.Cities;
      this.getIsFast();
    } else {
      this.fetch();
    }

    if (!!cart_info?.DistrictId && !!customLocation?.Districts) {
      setTimeout(() => {
        this.Districts = customLocation.Districts;
        this.DistrictId = cart_info.DistrictId;
      }, 500);
    }
    if (!!cart_info?.WardId && !!customLocation?.Wards) {
      setTimeout(() => {
        this.Wards = customLocation.Wards;
        this.WardId = cart_info.WardId;
      }, 500);
    }
    if (!!cart_info?.HamletId && !!customLocation?.Hamlets) {
      setTimeout(() => {
        this.Hamlets = customLocation.Hamlets;
        this.HamletId = cart_info.HamletId;
      }, 500);
    }
    if (
      !!this.cart_info?.CityId &&
      !!this.cart_info?.DistrictId &&
      !!this.cart_info?.WardId
    ) {
      this.getFee();
    }
    this.loading(false);
  },
  mounted() {
    //Lấy thông tin cart 
    this.isWholeSale = localStorage.getItem('phoneWholeSale');
    this.cart = JSON.parse(localStorage.getItem("cart"));
    EventBus.$emit('setCartValue', 1);
    window.insider_object = window.insider_object || {};

    window.insider_object.page = {
      type: "AddToCart",
    };
    if (this.cart && this.cart.length > 0) {
      window.insider_object.product = {
        id: this.cart[0]?.id,
        name: this.cart[0]?.product_name,
        currency: "VND",
        unit_price: this.cart[0]?.price,
        unit_sale_price: 0,
        url: window.location.href,
        product_image_url: this.cart[0]?.image_url,
        custom: {
          product_link: ``,
          cart: this.cart,
        },
      };

    }

    //lấy tỉnh thành phố
    this.fetch();
    if (localStorage.trackId == undefined) {
      localStorage.trackId = Math.random().toString(36).slice(2);
    }
    this.tracking(localStorage.trackId, 3);
    localStorage.userTrackStep = 3;
    if (this.$route.params.step == "hoan-thanh") {
      window.insider_object.page = {
        type: "Confirmation",
      };
      this.step = 4;
      this.cart = [];
      this.tracking(localStorage.trackId, this.step);
      //Xoá giỏ hàng trước đó
      localStorage.removeItem("cart");

      // payment with MoMo
      if (this.$route.query.orderType == "momo_wallet") {
        this.MoMoResponse = this.$route.query;
        useJwt
          .post("momo-update", this.$route.query)
          .then((response) => {
            if (response.data.result.length > 0) {
              return (this.OrderInfo = response.data.result[0]);
            }
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
              "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
              { icon: true, closeButton: "button" }
            );
          });
        return;
      }
      // payment with zalo pay
      if (this.$route.query.appid == "2166") {
        this.ZaloResponse = this.$route.query;
        if (this.ZaloResponse.status == "1") {
          useJwt
            .post("zalopay-update", {
              amount: this.$route.query.amount,
              appid: this.$route.query.appid,
              apptransid: this.$route.query.apptransid,
              bankcode: this.$route.query.bankcode,
              checksum: this.$route.query.checksum,
              discountamount: this.$route.query.discountamount,
              pmcid: this.$route.query.pmcid,
              status: this.$route.query.status,
            })
            .then((response) => {
              if (response.data.result.length > 0) {
                this.OrderInfo = response.data.result[0];
                return;
              }
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
                { icon: true, closeButton: "button" }
              );
            });
          return;
        }
      }

      this.VnPayResponse = this.$route.query;
      //  change type insider
      /*if (this.VnPayResponse.vnp_ResponseCode == "00") {
        window.insider_object.page = {
          type: "Confirmation",
        };
      }*/
      useJwt
        .post("vnpay-update", this.$route.query)
        .then((response) => {
          console.log(response.data.result);
          if (response.data.result.length > 0) {
            this.OrderInfo = response.data.result[0];
          }
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    } else {
      this.step = 1;
    }
  },
  methods: {
    closePopup() {
      this.$bvModal.hide('bv-modal-baseUS')
      this.$bvModal.hide('bv-modal-example');
      this.$bvModal.hide('bv-modal-sim-data');
      this.promotionCode = '';
    },
    handlePromotionCode() {
      this.isStatusUrbox = false;
      this.totalSalePrice = 0; 
    },
    getStatusPromotionCode() {
      this.getTotalPrice();
      this.promotionCode= this.promotionCode.trim();
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      let isBaseus = false;
      let isOppo  = false;
      if (this.promotionCode?.length > 0) {
        useJwt.get(`check-status-promotion-code/${promotionCode}`).then((response) => {
          if (response.data.code == 200 && response.data.result===null) {
            this.$bvModal.show('bv-modal-sim-data');
            return true;
          }
          this.cart.forEach((i)=> {
            if (i.origin_type == OriginType.BASUES) {
            isBaseus = true;
          } else if (i.origin_type == OriginType.OPPO) {
            isOppo = true;
          }
          })
          if (isOppo && isBaseus) {
            this.isUrbox = response.data.result.isUrbox;
            this.totalSalePrice = response.data.result.value;
            this.isStatusUrbox = true;
            if (this.totalSalePrice -  (this.getTotalPrice() - this.getShipmentFee())>=0) {
              this.totalSalePrice = this.getTotalPrice() - this.getShipmentFee();
            }
          } else {
            if (isOppo) {
              this.$bvModal.show('bv-modal-baseUS');
            }
            if(response.data.result && isBaseus){
              this.isUrbox = response.data.result.isUrbox;
              this.totalSalePrice = response.data.result.value;
              this.isStatusUrbox = true;
              if (this.totalSalePrice - (this.getTotalPrice() - this.getShipmentFee()) >=0) {
                this.totalSalePrice = this.getTotalPrice() - this.getShipmentFee();
              }
            }
          }
          return true;
        })
          .catch((err) => {
            this.$bvModal.show('bv-modal-example');
          })
      }
    },
    async fetch() {
      await useJwt
        .post("getMasterData/GHTK_Cities", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.Cities = response.data.result;
        })
        .catch((err) => {
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    removeProduct(p) {
      this.loading(true);
      if (p.product_type == 100) {
        this.cart = this.cart.filter((item) => item.Phone != p.Phone);
      } else {
        if (p.origin_type == OriginType.BASUES) {
          this.cart = this.cart.filter(
            (item) => !(item.id == p.id && item.selectIndex == p.selectIndex)
          );
        } else {
          /*To do sửa lại chỗ này*/
          this.cart = this.cart.filter((item) => item.uuid != p.uuid);
        }
      }
      localStorage.setItem("cart", JSON.stringify(this.cart));
      EventBus.$emit('setCartValue', 0);
      this.loading(false);

      let productPromotion = this.cart.filter(function (item) {
        return (
          item.categories &&
          item.categories[0] &&
          (item.categories[0].slug == "dien-thoai" ||
            item.categories[0].slug == "may-tinh-bang")
        );
      });
      if (productPromotion.length == 0) {
        this.cart = this.cart.filter((item) => item.product_type != 100);
      }
    
      if (
        this.cart.length == 0 ||
        this.cart.find((item) => item.product_type != 100) == undefined
      ) {
        localStorage.removeItem("cart");
        this.$router.push({ name: "home" });
      }
    },
    /**
     * bfd bổ sung thêm trường hợp thay đổi số lượng cho sản phẩm Baseus
     * @param {} p
     * @param {*} amount_
     */
    changeAmount(p, amount_) {
      this.getStatusPromotionCode();
      var itemChange;
      if (p.origin_type == OriginType.BASUES) {
        itemChange = this.cart.find(
          (item) => (item.id == p.id && item.selectIndex == p.selectIndex)
        );
      }
      //không phải là baseus thì giữa nguyên như cũ
      else {
        itemChange = this.cart.find((item) => item.uuid == p.uuid);
      }
      if (itemChange.amount + amount_ == 0) {
        this.removeProduct(itemChange);
      } else {
        itemChange.amount = itemChange.amount + amount_;
      } 
      localStorage.setItem("cart", JSON.stringify(this.cart));
       EventBus.$emit('setCartValue', itemChange);
    },
    formatNumber(x) {
      return formatNumber(x);
    },
    handleGetShippingFee() {
      if (debounceHandle) {
        clearTimeout(debounceHandle);
      }
      debounceHandle = setTimeout(() => {
        this.getShippingFee();
      }, 2000);
    },
    f087(value) {
      if (value) {
        return value.substr(0, 4) + " " + value.substr(4);
      } else {
        return value;
      }
    },
    redirectToSupport() {
      this.$router.push({
        path: this.$t("Route.Support") + this.$t("Route.ContactUs"),
      });
    },
    tracking(id, step) {
      const parsedParams = this.$route.query;
      this.$route.hash
        .split("&")
        .map((part) => part.replace(/^#/, ""))
        .forEach((param) => {
          const parts = param.split("=");
          parsedParams[parts[0]] = parts[1];
        });
      useJwt
        .post("tracking/" + id + "/" + step, {
          SimData: true,
          cart_info: this.cart_info,
          card: this.cart,
          query: parsedParams,
        })
        .then((response) => {
          //console.log(response.data.result)
        });
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["text", "text1"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    getShippingFee() {
      if (
        !(this.CityId && this.DistrictId && this.WardId && this.cart_info.addr)
      ) {
        this.expressShipInfo = {
          ahamoveFee: 0,
          lastFee: 0,
          deleveryTime: "",
        };
        return;
      }
      let fullAddress = this.cart_info.addr || "";
      if (this.WardId && this.Wards.find((el) => el.id == this.WardId)) {
        fullAddress += `, ${this.Wards.find((el) => el.id == this.WardId).text
          }`;
      }
      if (
        this.DistrictId &&
        this.Districts.find((el) => el.id == this.DistrictId)
      ) {
        fullAddress += `, ${this.Districts.find((el) => el.id == this.DistrictId).text
          }`;
      }
      if (this.CityId && this.Cities.find((el) => el.id == this.CityId)) {
        fullAddress += `, ${this.Cities.find((el) => el.id == this.CityId).text
          }`;
      }
      useJwt
        .post("get_fee_and_delivery_time", {
          city_id: this.CityId,
          addr: fullAddress,
          FullName: this.cart_info.fullName,
          ContactPhone: this.cart_info.ContactPhone,
        })
        .then((response) => {
          this.expressShipInfo = {
            ahamoveFee: response?.data?.result?.ahamove_fee || 0,
            lastFee: response?.data?.result?.last_fee || 0,
            deleveryTime: response?.data?.result?.delivery_time || "",
          };
        })
        .catch((err) => {
          this.$toast.error(
            err?.response?.data?.message ||
            "Có lỗi trong khi thực hiện tra cứu, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );

          this.expressShipInfo = {
            ahamoveFee: 0,
            lastFee: 0,
            deleveryTime: "",
          };
        });
    },
    onCityChange() {
      this.loading(true);
      this.getShippingFee();
      this.getIsFast();
    },
    onClickExpress() {
      if (!this.isExpress) {
        this.isExpress = true;
        this.isFast = false;
      }
    },
    onClickFast() {
      if (!this.isFast) {
        this.isFast = true;
        this.isExpress = false;
      }
    },
    change_shipment(e) {
      this.isLoading = true;
      this.cart_info.shipment_type = e;
      this.storeCollapse = this.cart_info.shipment_type == "home";
      this.isLoading = false;
    },
    getShipmentFee() {
      let carBaseus = this.cart.filter(function (item) {
        return item.origin_type == OriginType.BASUES;
      });
      if (carBaseus != null && carBaseus.length > 0 && this.CityId > 0) {
        //fix Baseus: nếu là HCM giư lại 20k với  còn với các tỉnh khác 30k
        if (this.CityId == ProvinceIdConst.HCM) return 20000;
        else return 30000;
      } else if (
        this.cart_info.shipment_type == "home" &&
        this.cart.find((item) => item.product_type == 100) != undefined
      ) {
        //return 15000; giá ship đã có trong sp
        return 0;
      } else {
        return 0;
      }
    },
    getTotalPrice() {
      var n = 0;
      this.cart.forEach((i) => {
        if (i.product_type != 100) {
          n += i.amount * i.price;
        } else {
          n += i.PackPrice;
        }
      });
      return (
        n +
        (this.isExpress && this.cart_info.shipment_type == "home"
          ? this.expressShipInfo.lastFee
          : this.getShipmentFee())
      );
    },

    chonThanhToanLai() {
      if (this.$route.query.orderType == "momo_wallet") {
        return this.thanhtoanlaiMoMo();
      } else {
        return this.thanhtoanlai();
      }
    },

    // Thanh toán lại bằng Ví MOMO
    thanhtoanlaiMoMo() {
      const urlPay = "get-momo-url-again/" + this.$route.query.orderId;
      this.loading(true);
      useJwt
        .get(urlPay)
        .then((response) => {
          if (response.data.result.url) {
            this.tracking(localStorage.trackId, "11");
            localStorage.userTrackStep = 11;
            document.location = response.data.result.url;
            return;
          }
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    // Thanh toán lại bằng Ví VNPAY
    thanhtoanlai() {
      const urlPay = "get-vnpay-url-again/" + this.VnPayResponse.vnp_TxnRef;
      this.loading(true);
      useJwt
        .get(urlPay)
        .then((response) => {
          var urlParams = new URLSearchParams(response.data.result.url);
          this.cart_info.vnp_TxnRef = urlParams.get("vnp_TxnRef");
          this.cart_info.old_vnp_TxnRef = this.VnPayResponse.vnp_TxnRef;
          //console.log(response.data.result);
          this.tracking(localStorage.trackId, "11");
          localStorage.userTrackStep = 11;
          console.log(this.cart_info);
          document.location = response.data.result.url;
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    selectStore(id) {
      this.isLoading = true;
      this.cart_info.StoreId = id.Id;
      this.isLoading = false;
      this.storeCollapse = true;
    },

    onPayment() {
      switch (this.paymentMethod) {
        case "VNPAY":
          return this.thanhtoan();
        case "BANK":
          return this.thanhtoan();
        case "INTCARD":
          return this.thanhtoan();
        case "MOMO":
          return this.thanhtoanMOMO();
        case "ZALO":
          return this.thanhtoanZALO();
      }
    },

    async thanhtoanZALO() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
          "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
          { icon: true, closeButton: "button" }
        );
        return;
      }
      console.log(this.cart_info);
      //return;
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
          this.cart_info.shipment_type == "itel" &&
          (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      this.promotionCode= this.promotionCode.trim();
      let isBaseus = false;
      let isOppo  = false;
      if (this.promotionCode?.length > 0) {
        useJwt.get(`check-status-promotion-code/${promotionCode}`).then((response) => {
          if (response.data.code == 200 && response.data.result===null) {
            this.$bvModal.show('bv-modal-sim-data');
            return true;
          }
          this.cart.forEach((i)=> {
            if (i.origin_type == OriginType.BASUES) {
            isBaseus = true;
          } else if (i.origin_type == OriginType.OPPO) {
            isOppo = true;
          }
          })
          if (isOppo && isBaseus) {
            this.isUrbox = response.data.result.isUrbox;
            this.totalSalePrice = response.data.result.value;
            this.isStatusUrbox = true;
            if (this.totalSalePrice -  (this.getTotalPrice() - this.getShipmentFee())>=0) {
              this.totalSalePrice = this.getTotalPrice() - this.getShipmentFee();
            }
          } else {
            if (isOppo) {
              this.$bvModal.show('bv-modal-baseUS');
            }
            if(response.data.result && isBaseus){
              this.isUrbox = response.data.result.isUrbox;
              this.totalSalePrice = response.data.result.value;
              this.isStatusUrbox = true;
              if (this.totalSalePrice -  (this.getTotalPrice() - this.getShipmentFee())>=0) {
                this.totalSalePrice = this.getTotalPrice() - this.getShipmentFee();
              }
            }
          }
          this.getTotalPrice();
          return true;
        })
          .catch((err) => {
            this.$bvModal.show('bv-modal-example');
          })
      }
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      //bfd nếu là baseus thì remove description, với thông tin bên woo
    
      var phoneWholeSale = localStorage.getItem('phoneWholeSale');
      var isWholeSale = phoneWholeSale ? true : false;
      
      var listItemBaseus = [];
      this.cart.forEach((x) => {
        if (x.origin_type == OriginType.BASUES) {
          x.body_html = "";
          x.isWholeSale = isWholeSale;
          x.variants.forEach((variant) => {
            variant.wooProduct = {};
          });
          if (
            x.variants[x.selectIndex] &&
            x.variants[x.selectIndex].wooProduct
          ) {
            x.variants[x.selectIndex].wooProduct = {};
          }
          //lưuư thêm thông tin sản phẩm đang dc chọn
          var selected = { value: x.variants[x.selectIndex].title };
          selected["priceWoo"] = x.variants[x.selectIndex].priceWoo;
          x.variants[0].selected = selected;
          if (x.variant_id) {
            listItemBaseus.push({
              quantity: x.amount,
              title: x.title,
              variant_id: x.variant_id,
              variant_title: x.variant_title,
              vendor: x.vendor,
              price: x.variants[x.selectIndex].priceWoo,
              price_promotion: x.priceWoo,
              type: "Khác"
            });
          }
        }
      });
      //tạo đơn baseus -> nếu thành công gán thêm ref_id để lưu và theo dõi + confirm
      if (listItemBaseus && listItemBaseus.length > 0) {
        if (!this.isValidBaseusWholeSale(listItemBaseus)) {
          return;
        }
        var result = await this.createOrderBaseus(listItemBaseus);
        console.log(result);
        if (
          result == null ||
          result.status != 200 ||
          !result.data ||
          !result.data.result
        ) {
          this.$toast.warning("Có lỗi thanh toán. Xin vui lòng thử lại sau", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
        if (result.data && result.data.result) {
          var oderBaseus = JSON.parse(result.data.result);
          this.cart.forEach((x) => {
            if (x.origin_type == OriginType.BASUES) {
              x.ref_id = oderBaseus.order.id;
              x.BaseusOrderNumber = oderBaseus.order.order_number;
            }
          });
        }
      }
      useJwt
        .post("get-zalopay-url", {
          Phone: this.cart[0].Phone,
          PromotionCode: promotionCode,
          TotalPrice: this.getTotalPrice()- this.totalSalePrice,
          ShipmentFee: this.getShipmentFee(),
          vnp_Bill_Mobile: this.cart_info.ContactPhone,
          fullName: this.cart_info.fullName,
          cart_info: this.cart_info,
          cart: this.cart,
          transactionType: "DEVICE",
          WholeSalePhone:phoneWholeSale
        })
        .then((response) => {
          this.tracking(localStorage.trackId, "10");
          localStorage.userTrackStep = 10;
          document.location = response.data.result.url;
          this.loading(false);
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status == 400) {
            this.$bvModal.show("modal-dup");
          } else {
            // this.$toast.error(
            //   "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
            //   {icon: true, closeButton: "button"}
            // );
          }
          this.loading(false);
        });
    },
    async thanhtoanMOMO() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
          "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
          { icon: true, closeButton: "button" }
        );
        return;
      }
      console.log(this.cart_info);
      //return;
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
          this.cart_info.shipment_type == "itel" &&
          (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      this.promotionCode= this.promotionCode.trim();
      let isBaseus = false;
      let isOppo  = false;
      if (this.promotionCode?.length > 0) {
        useJwt.get(`check-status-promotion-code/${promotionCode}`).then((response) => {
          if (response.data.code == 200 && response.data.result===null) {
            this.$bvModal.show('bv-modal-sim-data');
            return true;
          }
          this.cart.forEach((i)=> {
            if (i.origin_type == OriginType.BASUES) {
              isBaseus = true;
            } else if (i.origin_type == OriginType.OPPO) {
              isOppo = true;
            }
          })
          if (isOppo && isBaseus) {
            this.isUrbox = response.data.result.isUrbox;
            this.totalSalePrice = response.data.result.value;
            this.isStatusUrbox = true;
            if (this.totalSalePrice -  (this.getTotalPrice() - this.getShipmentFee())>=0) {
              this.totalSalePrice = this.getTotalPrice() - this.getShipmentFee();
            }
          } else {
            if (isOppo) {
              this.$bvModal.show('bv-modal-baseUS');
            }
            if(response.data.result && isBaseus){
              this.isUrbox = response.data.result.isUrbox;
              this.totalSalePrice = response.data.result.value;
              this.isStatusUrbox = true;
              if (this.totalSalePrice -  (this.getTotalPrice() - this.getShipmentFee())>=0) {
                this.totalSalePrice = this.getTotalPrice() - this.getShipmentFee();
              }
            }
          }
          this.getTotalPrice();
          return true;
        })
          .catch((err) => {
            this.$bvModal.show('bv-modal-example');
          })
      }
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      //bfd nếu là baseus thì remove description, với thông tin bên woo
      var phoneWholeSale = localStorage.getItem('phoneWholeSale');
      var isWholeSale = phoneWholeSale ? true : false;

      var listItemBaseus = [];
      this.cart.forEach((x) => {
        if (x.origin_type == OriginType.BASUES) {
          x.body_html = "";
          x.isWholeSale = isWholeSale;
          x.variants.forEach((variant) => {
            variant.wooProduct = {};
          });
          //lưuư thêm thông tin sản phẩm đang dc chọn
          if (
            x.variants[x.selectIndex] &&
            x.variants[x.selectIndex].wooProduct
          ) {
            x.variants[x.selectIndex].wooProduct = {};
          }
          var selected = { value: x.variants[x.selectIndex].title };
          selected["priceWoo"] = x.variants[x.selectIndex].priceWoo;
          x.variants[0].selected = selected;
          if (x.variant_id) {
            listItemBaseus.push({
              quantity: x.amount,
              title: x.title,
              variant_id: x.variant_id,
              variant_title: x.variant_title,
              vendor: x.vendor,
              price: x.variants[x.selectIndex].priceWoo,
              price_promotion: x.priceWoo,
              type: "Khác"
            });
          }
        }
      });
      //tạo đơn baseus -> nếu thành công gán thêm ref_id để lưu và theo dõi + confirm
      if (listItemBaseus && listItemBaseus.length > 0) {
        if (!this.isValidBaseusWholeSale(listItemBaseus)) {
          return;
        }
        var result = await this.createOrderBaseus(listItemBaseus);
        console.log(result); 
        if (
          result == null ||
          result.status != 200 ||
          !result.data ||
          !result.data.result
        ) {
          this.$toast.warning("Có lỗi thanh toán. Xin vui lòng thử lại sau", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
        if (result.data && result.data.result) {
          var oderBaseus = JSON.parse(result.data.result);
          this.cart.forEach((x) => {
            if (x.origin_type == OriginType.BASUES) {
              x.ref_id = oderBaseus.order.id;
              x.BaseusOrderNumber = oderBaseus.order.order_number;
            }
          });
        }
      }
      useJwt
        .post("get-momo-url", {
          Phone: this.cart[0].Phone,
          PromotionCode: promotionCode,
          TotalPrice: this.getTotalPrice()- this.totalSalePrice,
          ShipmentFee:
            this.isExpress && this.cart_info.shipment_type == "home"
              ? this.expressShipInfo.lastFee
              : this.getShipmentFee(),
          vnp_Bill_Mobile: this.cart_info.ContactPhone,
          fullName: this.cart_info.fullName,
          cart_info: this.cart_info,
          cart: this.cart,
          transactionType: "DEVICE",
          WholeSalePhone: phoneWholeSale
        })
        .then((response) => {
          this.tracking(localStorage.trackId, "10");
          localStorage.userTrackStep = 10;
          document.location = response.data.result.url;
          this.loading(false);
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status == 400) {
            this.$bvModal.show("modal-dup");
          } else {
            // this.$toast.error(
            //   "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
            //   {icon: true, closeButton: "button"}
            // );
          }
          this.loading(false);
        });
    },
    async onOrder() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });

      if (!validate) {
        this.$toast.warning(
          "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
          { icon: true, closeButton: "button" }
        );
        return;
      }

      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
          this.cart_info.shipment_type == "itel" &&
          (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }

      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;

      const data = {
        Phone: this.cart_info.ContactPhone,
        TotalPrice: this.getTotalPrice()- this.totalSalePrice,
        ShipmentFee:
          this.isExpress && this.cart_info.shipment_type == "home"
            ? this.expressShipInfo.lastFee
            : this.getShipmentFee(),
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
      };
      parent.postMessage({ type: "onOrder", data }, "*");
    },
    async thanhtoan() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });

      if (!validate) {
        this.$toast.warning(
          "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
          { icon: true, closeButton: "button" }
        );
        return;
      }

      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
          this.cart_info.shipment_type == "itel" &&
          (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsSimData = 1;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      //bfd nếu là baseus thì remove description, với thông tin bên woo
      var phoneWholeSale = localStorage.getItem('phoneWholeSale');
      var isWholeSale = phoneWholeSale ? true : false;

      var listItemBaseus = [];
      this.cart.forEach((x) => {
        if (x.origin_type == OriginType.BASUES) {
          x.body_html = "";
          x.isWholeSale = isWholeSale;
          x.variants.forEach((variant) => {
            variant.wooProduct = {};
          });
          //lưuư thêm thông tin sản phẩm đang dc chọn
          if (
            x.variants[x.selectIndex] &&
            x.variants[x.selectIndex].wooProduct
          ) {
            x.variants[x.selectIndex].wooProduct = {};
          }
          var selected = { value: x.variants[x.selectIndex].title };
          selected["priceWoo"] = x.variants[x.selectIndex].priceWoo;
          x.variants[0].selected = selected;
          if (x.variant_id) {
            listItemBaseus.push({
              quantity: x.amount,
              title: x.title,
              variant_id: x.variant_id,
              variant_title: x.variant_title,
              vendor: x.vendor,
              price: x.variants[x.selectIndex].priceWoo,
              type: "Khác"
            });
          }
        }
      });

      //tạo đơn baseus -> nếu thành công gán thêm ref_id để lưu và theo dõi + confirm
      if (listItemBaseus && listItemBaseus.length > 0) {
        if (!this.isValidBaseusWholeSale(listItemBaseus)) {
          return;
        }
        var result = await this.createOrderBaseus(listItemBaseus);
        console.log(result); 
        if (
          result == null ||
          result.status != 200 ||
          !result.data ||
          !result.data.result
        ) {
          this.$toast.warning("Có lỗi thanh toán. Xin vui lòng thử lại sau", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
        if (result.data && result.data.result) {
          var oderBaseus = JSON.parse(result.data.result);
          this.cart.forEach((x) => {
            if (x.origin_type == OriginType.BASUES) {
              x.ref_id = oderBaseus.order.id;
              x.BaseusOrderNumber = oderBaseus.order.order_number;
            }
          });
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      this.promotionCode= this.promotionCode.trim();
      let isBaseus = false;
      let isOppo  = false;
      if (this.promotionCode?.length > 0) {
        useJwt.get(`check-status-promotion-code/${promotionCode}`).then((response) => {
          if (response.data.code == 200 && response.data.result===null) {
            this.$bvModal.show('bv-modal-sim-data');
            return true;
          }
          this.cart.forEach((i)=> {
            if (i.origin_type == OriginType.BASUES) {
            isBaseus = true;
            } else if (i.origin_type == OriginType.OPPO) {
              isOppo = true;
            }
          })
          if (isOppo && isBaseus) {
            this.isUrbox = response.data.result.isUrbox;
            this.totalSalePrice = response.data.result.value;
            this.isStatusUrbox = true;
            if (this.totalSalePrice -  (this.getTotalPrice() - this.getShipmentFee())>=0) {
              this.totalSalePrice = this.getTotalPrice() - this.getShipmentFee();
            }
          } else {
            if (isOppo) {
              this.$bvModal.show('bv-modal-baseUS');
            }
            if(response.data.result && isBaseus){
              this.isUrbox = response.data.result.isUrbox;
              this.totalSalePrice = response.data.result.value;
              this.isStatusUrbox = true;
              if (this.totalSalePrice -  (this.getTotalPrice() - this.getShipmentFee())>=0) {
                this.totalSalePrice = this.getTotalPrice() - this.getShipmentFee();
              }
            }
          }
          this.getTotalPrice();
          return true;
        })
          .catch((err) => {
            this.$bvModal.show('bv-modal-example');
          })
      }
      this.loading(true);
      this.saveToSession();
      useJwt
        .post("get-vnpay-url", {
          Phone: this.cart_info.ContactPhone,
          PromotionCode: promotionCode,
          TotalPrice: this.getTotalPrice()- this.totalSalePrice,
          ShipmentFee:
            this.isExpress && this.cart_info.shipment_type == "home"
              ? this.expressShipInfo.lastFee
              : this.getShipmentFee(),
          vnp_Bill_Mobile: this.cart_info.ContactPhone,
          fullName: this.cart_info.fullName,
          cart_info: this.cart_info,
          cart: this.cart,
          transactionType: "DEVICE",
          WholeSalePhone: phoneWholeSale
        })
        .then((response) => {
          var urlParams = new URLSearchParams(response.data.result.url);
          this.cart_info.vnp_TxnRef = urlParams.get("vnp_TxnRef");
          this.tracking(localStorage.trackId, "10");
          localStorage.userTrackStep = 10;

          document.location = response.data.result.url;
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          // this.$toast.error(
          //     "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
          //     {icon: true, closeButton: "button"}
          // );
        });
    },
    /**
     * Lưu lại thông tin để
     */
    saveToSession() {
      sessionStorage.setItem("cart_info", JSON.stringify(this.cart_info));
      sessionStorage.setItem(
        "customer_location",
        JSON.stringify({
          Cities: this.Cities,
          Districts: this.Districts,
          Wards: this.Wards,
          Hamlets: this.Hamlets,
        })
      );
    },
    getIsFast() {
      useJwt
        .get(`is_fast/${this.CityId}`)
        .then((response) => {
          this.hasExpressShip = !!response?.data?.result;
          if (!this.hasExpressShip) {
            this.isExpress = false;
            this.isFast = true;
          }
          this.loading(false);
        })
        .catch((err) => {
          this.hasExpressShip = false;
          this.isExpress = false;
          this.isFast = true;
          this.loading(false);
        });
    },
    isValidBaseusWholeSale(listItem) {
      var phoneWholeSale = localStorage.getItem('phoneWholeSale');
      if (!phoneWholeSale) {
        return true;
      }
      var totalAmount = 0; var totalCount = 0;
      listItem.forEach(function (item) {
        totalAmount +=
          (item.quantity ? item.quantity : 0) * (item.price ? item.price : 0);
        totalCount += (item.quantity ? item.quantity : 0);
      });
      
      if (totalAmount < 5250000 && totalCount < 5) {
        this.erroWhole="Bạn ơi, đơn hàng mua sỉ chưa đạt yêu cầu. Hãy chọn thêm sản phẩm để thỏa mãn 2 điều kiện:   <br> 1. Giỏ hàng ≥ 05 sản phẩm   <br> 2. Tổng giá trị đơn hàng ≥ 5.250.000 VNĐ.<br>Bạn vui lòng thực hiện lại nhé!";
        this.$bvModal.show("modal-whole-sale");
        return false;
      }
     else if (totalAmount < 5250000 ) {
      this.erroWhole="Bạn ơi, đơn hàng mua sỉ chưa đủ 5.250.000 đồng, vui lòng chọn thêm nhé!";
        this.$bvModal.show("modal-whole-sale");
        return false;
      }
      else if (totalCount < 5) {
        this.erroWhole="Bạn ơi, đơn hàng mua sỉ chưa đủ 05 sản phẩm, vui lòng chọn thêm nhé!";
        this.$bvModal.show("modal-whole-sale");
        return false;
      }
      return true;
    },
    /**
     * tạo đơn baseus
     * @param {tao} listItem
     */
    async createOrderBaseus(listItem) {
      try {
        var totalAmount = 0;
        listItem.forEach(function (item) {
          totalAmount +=
            (item.quantity ? item.quantity : 0) * (item.price ? item.price : 0);
        });
        //nếu đủ dk của mua sỉ thì giảm 5% giá trị đơn hàng đẩy sang baseus 
        if (listItem.length >= 5 && totalAmount >= 5250000) {
          totalAmount = totalAmount * 0.95;
        }
        var address =
          this.Wards.find((el) => el.id == this.WardId).text +
          ", " +
          this.Districts.find((el) => el.id == this.DistrictId).text +
          ", " +
          this.Cities.find((el) => el.id == this.CityId).text;
        if (this.HamletId > 0) {
          address =
            this.Hamlets.find((el) => el.id == this.HamletId).text +
            ", " +
            address;
        }
        if (this.cart_info.addr && this.cart_info.addr.length > 0) {
          address = this.cart_info.addr + ", " + address;
        }
        return await useJwt.post("/baseus/createdOrder", {
          order: {
            email: this.cart_info.email,
            phone: this.cart_info.ContactPhone,
            first_name: this.cart_info.fullName,
            total_spent: totalAmount,
            fulfillment_status: "notfulfilled",
            note: "Đơn hàng từ iTel",
            // source:'App ITEL',
            line_items: listItem,
            shipping_address: {
              address1: address,
              city: this.Cities.find((el) => el.id == this.CityId).text,
              country: "Vietnam",
              last_name: this.cart_info.fullName,
              phone: this.cart_info.ContactPhone,
              province: this.Cities.find((el) => el.id == this.CityId).text,
              name: this.cart_info.fullName,
              province_code: this.Cities.find((el) => el.id == this.CityId)
                .code,
              country_code: "VN",
              default: true,
              district: this.Districts.find((el) => el.id == this.DistrictId)
                .text,
              district_code: this.Districts.find(
                (el) => el.id == this.DistrictId
              ).code,
              ward: this.Wards.find((el) => el.id == this.WardId).text,
              ward_code: this.Wards.find((el) => el.id == this.WardId).code,
            },
            shipping_lines: [
              {
                price: this.getShipmentFee(),
              },
            ],
          },
        });
      } catch (ex) {
        return null;
      }
    },
    Img(pic) {
      return images[pic];
    },
    preventEnterTextCharacter(event) {
      const regex = /[a-zA-Z]/;
      var key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    gotoPolicy() {
      if (this.cart && this.cart.length == 1) {
        if (this.cart[0].origin_type == OriginType.BASUES) {
          window.open("https://baseus.vn/pages/chinh-sach-bao-hanh", "_blank");
        } else if (this.cart[0].origin_type == OriginType.OPPO) {
          window.open(
            "https://itel.vn/tin-tuc/tin-dich-vu/chinh-sach-mua-hang-va-bao-hanh-san-pham-oppo-tren-website-itel.vn",
            "_blank"
          );
        } else {
          window.open("https://itel.vn/thong-tin/mua-sim-so-itel", "_blank");
        }
      } else if (this.cart.length > 1) {
        var isSim = false;
        var isBaseus = false;
        var isOppo = false;
        this.listPolicys = [];
        this.cart.forEach(function (item) {
          if (item.product_type == 100) {
            isSim = true;
          }
          if (item.origin_type == OriginType.BASUES) {
            isBaseus = true;
          } else if (item.origin_type == OriginType.OPPO) {
            isOppo = true;
          }
        });
        if (isSim) {
          this.listPolicys.push(
            '. Chính sách mua SIM số iTel: <br/>- Bao gồm các chính sách về giá; chính sách sản phẩm cho SIM thường, SIM số cam kết (Số VIP), eSIM; chính sách lưu trữ thông tin. Chi tiết vui lòng xem <a href="https://itel.vn/thong-tin/mua-sim-so-itel"class="text-primary text-decoration-underline" target="_blank">TẠI ĐÂY </a>'
          );
        }
        if (isBaseus) {
          this.listPolicys.push(
            '. Chính sách mua sản phẩm Baseus: <br/>- Bao gồm các quy định về thời gian bảo hành; điều kiện để được bảo hành;  chính sách bảo hành; trung tâm bảo hành trên cả nước;… Chi tiết vui lòng xem <a href="https://baseus.vn/pages/chinh-sach-bao-hanh" class="text-primary text-decoration-underline" target="_blank">TẠI ĐÂY </a>'
          );
        }
        if (isOppo) {
          this.listPolicys.push(
            '. Chính sách mua sản phẩm OPPO: <br/> - Bao gồm các quy định về thời gian bảo hành; điều kiện để được bảo hành;  một số lưu ý quan trọng; dịch vụ sửa chữa ngoài bảo hành… Chi tiết vui lòng xem <a href="https://itel.vn/tin-tuc/tin-dich-vu/chinh-sach-mua-hang-va-bao-hanh-san-pham-oppo-tren-website-itel.vn" class="text-primary text-decoration-underline" target="_blank">TẠI ĐÂY </a>'
          );
        }
        this.$bvModal.show("modal-policy");
      }
    },
    getFee: async function (i, v) {
      this.isLoadingFee = true;
      this.getShippingFee();
      await useJwt
        .get(
          "ghtk/fee?province=" +
          this.CityId +
          "&district=" +
          this.DistrictId +
          "&ward=" +
          this.WardId
        )
        .then((response) => {
          this.cart_info = {
            ...this.cart_info,
            ghtk_fee: response.data.result.fee.fee,
          };
          this.isLoadingFee = false;
        })
        .catch((err) => {
          this.cart_info = {
            ...this.cart_info,
            ghtk_fee: 0,
          };
          this.isLoadingFee = false;
        });
    },
    ChangePaymentMethod(method) {
      this.paymentMethod = method;
    },
    wholeContinue() {
      this.$bvModal.hide("modal-whole-sale");
      this.$router.push({ name: 'products', params: { Slug: 'tat-ca' } });
    },
    gotoHome() {
      this.$router.push({ name: 'home' });
    }
  },
};
</script>
<style lang="scss">
.modal-body {
  padding: 0;
}
.b2c__btn {
  margin-left: 10px;
  font-size: 14px !important;
  width: 25%;
  height: 40px !important;
  border: none;
}
.modal-header .close {
  display: none;
}

.modal .modal-content {
  width: 360px;
  border-radius: 10px;
  margin: 0 auto; 
}
.btn-secondary:hover,
.btn-secondary {
  border-color: none !important;
}

@media screen and (max-width: 450px) {
  .b2c__btn {
    width: 55%;
  }
}

@media screen and (max-width: 1199px) {
  .b2c-o-h {
    padding: 0px 20px;
  }

  .b2c-pc-pack-content .b2c-pc-info,
  .b2c-pc-payment {
    margin-top: 10px !important;
  }

  .huge-text {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 755px) {

  .cart .image,
  .cart .info {
    width: 100% !important;
  }

  .cart .info {
    padding-top: 10px;
  }

  .b2c-pc-pack-content .cart {
    height: 415px !important;
  }

  .cart .image img.image-product {
    object-fit: contain !important;
  }
}

.arrow-icon-end {
  background-image: url("../../assets/images/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: 10px 18px;
  background-position: 95% 50%;
  padding: 11.5px !important;
}

.policy-popup {
  line-height: 30px;
}

.text-decoration-underline {
  text-decoration: underline;
  cursor: pointer;
}

.check-box-policy {
  display: flex;
  align-items: center;

  .custom-control-label {
    padding-left: 15px !important;
  }
}

.disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.6;
}

.shipping-nav {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 !important;
}

.fs-sp .shipping-nav {
  overflow-y: visible;
  overflow-x: scroll;
}

.fs-sp .shipping-nav::-webkit-scrollbar {
  display: none;
}

.shipping-nav li a {
  white-space: nowrap;
}
</style>
<style>
.table-step {
  width: 100%;
}

.table-step td {
  width: 25%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 0.5rem;
}

.b2c-bg-top {
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}

.b2c-top-left::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}

.b2c-top-left {
  height: 44px;
  padding: 8px 14px;
  width: 20%;
}

.b2c-top-right {
  width: 20%;
}

.b2c-top-right1::before {
  width: 20%;
}

.b2c-top-center {
  width: 80%;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-top: 7px;
}

.b2c-steps {
  width: 100%;
  height: 42px;
  background: #d71a1f;
}

.b2c-text-solo {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
}

.b2c-bg {
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}

.b2c-number-087 {
  width: 25%;
  text-align: right;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 36px;
  color: #fff;
  padding-top: 3px;
}

.b2c-number-msisdn {
  width: 50%;
  padding: 0px 10px;
}

.b2c-number-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  letter-spacing: 0px;
  padding-left: 0px;
}

.b2c-number-input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  height: 45px;
  color: #ed1f24;
  width: 100%;
  border: none;
  background: #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  letter-spacing: 3px;
  padding-left: 10px;
}

.b2c-number-btn {
  width: 25%;
}

.b2c-number-btn button {
  border: 1px solid #fff;
  width: 49px;
  height: 45px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE4TDE0LjIyMjMgMTQuMjE1NkwxOCAxOFpNMTYuMzE1OCA5LjE1Nzg5QzE2LjMxNTggMTEuMDU2MyAxNS41NjE3IDEyLjg3NjkgMTQuMjE5MyAxNC4yMTkzQzEyLjg3NjkgMTUuNTYxNyAxMS4wNTYzIDE2LjMxNTggOS4xNTc4OSAxNi4zMTU4QzcuMjU5NSAxNi4zMTU4IDUuNDM4ODYgMTUuNTYxNyA0LjA5NjUgMTQuMjE5M0MyLjc1NDEzIDEyLjg3NjkgMiAxMS4wNTYzIDIgOS4xNTc4OUMyIDcuMjU5NSAyLjc1NDEzIDUuNDM4ODYgNC4wOTY1IDQuMDk2NUM1LjQzODg2IDIuNzU0MTMgNy4yNTk1IDIgOS4xNTc4OSAyQzExLjA1NjMgMiAxMi44NzY5IDIuNzU0MTMgMTQuMjE5MyA0LjA5NjVDMTUuNTYxNyA1LjQzODg2IDE2LjMxNTggNy4yNTk1IDE2LjMxNTggOS4xNTc4OVY5LjE1Nzg5WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==") no-repeat center;
}

.b2c-number--container {
  padding: 0 25px;
  height: 36px;
  background: #d71a1f;
  width: 100%;
}

.b2c-number-except {
  margin: 0 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-except-e {
  margin: 0 25px;
  height: auto;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-type {
  margin-left: 25px;
  margin-right: 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-price {
  margin: 0 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 30px;
}

.b2c-number-except-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-number-except-buttons {
  padding: 0px;
  min-height: 46px;
  overflow: hidden;
}

.b2c-number-except-btn {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #fff;
  width: 29px;
  height: 29px;
}

.b2c-number-except-btn-checked {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #ed1f24;
  width: 29px;
  height: 29px;
}

.b2c-number-except-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-number-except-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3LjE0MjgyTDcgMS4xNDI4MkwxIDcuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-number-type-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-number-type-select option {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.b2c-number-type-select {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  background: none;
  border: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 18px;
  margin-right: 16px;
  padding-right: 16px;
}

.b2c-search-button {
  width: calc(100% - 50px);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  color: #424242;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  height: 48px;
  background: #ffffff;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  margin: 25px;
}

.b2c-search-button-red {
  color: #ffffff !important;
  background: #ed1f24 !important;
}

.b2c-pagination-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-top: 4px;
  color: #424242;
  width: 55%;
}

.b2c-pagination-title-sort {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #424242;
  background: #fff;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 4px 8px;
  border: none;
  width: 45%;
  text-align: right;
}

.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}

.b2c-badge {
  font-size: 14px;
}

.b2c-buy-btn {
  border: 2px solid #ed1f24;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  font-size: 16px;
  color: #ed1f24;
  height: 34px;
  width: 96px;
}

.b2c-page-msisdn {
  color: #000;
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 12px;
  letter-spacing: 2px;
}

.b2c-camket-text {
  font-size: 14px;
  margin-top: 12px;
}

.b2c-price-text {
  font-weight: 400;
  font-size: 24px;
  padding-top: 12px;
  letter-spacing: 0.5px;
  color: #424242;
}

.b2c-price-text b {
  color: #ed1f24;
}

.b2c-package-bg-selected {
  background: #f9f9f9;
}

.b2c-package-title-selected {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  text-align: left;
  width: 50%;
  font-size: 36px;
  color: #ed1f24;
}

.b2c-package-subtitle-selected {
  font-family: "Roboto";
  font-style: normal;
  text-align: right;
  font-weight: 700;
  font-size: 20px;
  width: 50%;
  color: #424242;
  padding-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: end;
}

.b2c-package-subtitle-selected p {
  padding-bottom: 2px;
}

.b2c-package-brief-selected {
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}

.b2c-package-brief-selected li {
  margin-top: 10px;
}

.b2c-package-brief-selected li::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
  padding-right: 10px;
  vertical-align: sub;
}

.b2c-package-brief-selected .pack-txt {
  text-align: left;
}

.b2c-package-title {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  text-align: center;
  width: 100%;
  font-size: 36px;
  color: #ed1f24;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjU5NDMgMTcuOTQ5N0MxNi4yMDM4IDE4LjM0MDMgMTUuNTcwNiAxOC4zNDAzIDE1LjE4MDEgMTcuOTQ5N0wxMy43NjU5IDE2LjUzNTVDMTMuMzc1MyAxNi4xNDUgMTIuNzQyMiAxNi4xNDUgMTIuMzUxNiAxNi41MzU1VjE2LjUzNTVDMTEuOTYxMSAxNi45MjYxIDExLjk2MTEgMTcuNTU5MiAxMi4zNTE2IDE3Ljk0OTdMMTUuMTgwMSAyMC43NzgyQzE1LjU3MDYgMjEuMTY4NyAxNi4yMDM4IDIxLjE2ODcgMTYuNTk0MyAyMC43NzgyTDIyLjI1MTEgMTUuMTIxM0MyMi42NDE3IDE0LjczMDggMjIuNjQxNyAxNC4wOTc2IDIyLjI1MTEgMTMuNzA3MVYxMy43MDcxQzIxLjg2MDYgMTMuMzE2NiAyMS4yMjc0IDEzLjMxNjYgMjAuODM2OSAxMy43MDcxTDE2LjU5NDMgMTcuOTQ5N1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMiAxNi41QzMyIDI1LjA2MDQgMjUuMDYwNCAzMiAxNi41IDMyQzcuOTM5NTkgMzIgMSAyNS4wNjA0IDEgMTYuNUMxIDcuOTM5NTkgNy45Mzk1OSAxIDE2LjUgMUMyNS4wNjA0IDEgMzIgNy45Mzk1OSAzMiAxNi41WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 10px;
}

.b2c-package-subtitle {
  font-family: "Roboto";
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  color: #424242;
}

.b2c-package-brief {
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}

.b2c-package-brief li {
  margin-top: 10px;
}

.b2c-package-brief li::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNTc0ODkgOC45MDE1OUM4LjE4NDM2IDkuMjkyMTIgNy41NTEyIDkuMjkyMTIgNy4xNjA2OCA4LjkwMTU5TDYuNzU5MDkgOC41QzYuNTU0OTggOC4yOTU5IDYuMjI0MDYgOC4yOTU5IDYuMDE5OTYgOC41VjguNUM1LjgxNTg1IDguNzA0MTEgNS44MTU4NSA5LjAzNTAzIDYuMDE5OTYgOS4yMzkxM0w3LjE2MDY4IDEwLjM3OTlDNy41NTEyIDEwLjc3MDQgOC4xODQzNiAxMC43NzA0IDguNTc0ODkgMTAuMzc5OUwxMS4xOTM5IDcuNzYwODdDMTEuMzk4IDcuNTU2NzcgMTEuMzk4IDcuMjI1ODUgMTEuMTkzOSA3LjAyMTc0VjcuMDIxNzRDMTAuOTg5OCA2LjgxNzY0IDEwLjY1ODggNi44MTc2NCAxMC40NTQ3IDcuMDIxNzRMOC41NzQ4OSA4LjkwMTU5WiIgZmlsbD0iIzQyNDI0MiIvPgo8cGF0aCBkPSJNMTYgOC41QzE2IDEyLjY0MjEgMTIuNjQyMSAxNiA4LjUgMTZDNC4zNTc4NiAxNiAxIDEyLjY0MjEgMSA4LjVDMSA0LjM1Nzg2IDQuMzU3ODYgMSA4LjUgMUMxMi42NDIxIDEgMTYgNC4zNTc4NiAxNiA4LjVaIiBzdHJva2U9IiM0MjQyNDIiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");
  padding-right: 10px;
}

.b2c-package-brief .pack-txt,
.b2c-package-brief-selected .pack-txt {
  text-align: left;
}

.b2c-package-brief-selected .pack-txt,
.b2c-package-brief .pack-txt {
  margin-bottom: 10px;
}

.b2c-float-bg {
  height: 120px;
  background: rgba(237, 31, 36, 1);
  position: absolute;
}

.b2c-cart-price {
  position: absolute;
  min-width: 100px;
  width: auto;
  height: 63px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  right: 20px;
  background: #18d3c9;
  font-style: italic;
  font-size: 16px;
  color: #fff;
  font-weight: 900;
  text-align: center;
  padding: 13px;
}

.b2c-cart-price span {
  font-size: 24px;
}

.b2c-cart-add-more {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.b2c-cart-info-title {
  height: 46px;
  background: #eaeaea;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  padding: 10px 24px;
  border-bottom: 1px solid #e0e0e0;
}

.b2c-cart-info-title-text {
  height: 46px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  padding: 1rem 1rem;
}

.b2c-agree-text button {
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}

.b2c-store-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAyTDYuNSA3TDIgMiIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}

.b2c-store-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSA3TDYuNSAyTDIgNyIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}

.b2c-bg-selected {
  background: #17e0d5;
}

.b2c-bg-selected .b2c-page-msisdn,
.b2c-bg-selected .b2c-price-text,
.b2c-bg-selected .b2c-camket-text,
.b2c-bg-selected .b2c-price-text b {
  color: #fff;
}

.b2c-text-solo h1 {
  font-size: 28px;
  letter-spacing: 3px;
  font-weight: 500;
  color: #fff;
}

.b2c-text-solo p {
  color: #fff;
  font-size: 16px;
  padding: 0 10%;
}

.b2c-search-button-red:disabled {
  background: #999999 !important;
}

.b2c-cart-bg-del {
  border-radius: 26.8px;
  -webkit-border-radius: 26.8px;
  border: 1.5px solid #ed1f24;
  padding: 6px 16px;
}

.b2c-price-texts {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.b2c-rate-text {
  font-size: 16px;
  line-height: 1.4;
}

.vue-star-rating {
  display: inline-block !important;
}

.b2c-ghtk-fee {
  font-size: 14px;
  text-decoration-line: line-through !important;
}

.b2c-o-h {
  max-width: 100%;
  overflow-x: hidden;
}

.text1 {
  font-size: 12px;
  line-height: 1.5;
}

.b2c-page-bg .custom-checkbox.custom-control {
  padding-left: 2.2rem;
}

.b2c-page-bg .custom-control-label {
  line-height: 1.2;
}

.b2c-pc-pack-content {
  min-height: 550px;
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
}

.b2c-pc-pack-content .b2c-pc-info {
  margin-top: 50px;
}

.b2c-pc-pack-content .b2c-pc-info .card-header {
  background: #f8f8f8;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.b2c-pc-pack-content .b2c-package-bg-selected {
  max-width: 457px;
  margin-left: auto;
  margin-right: auto;
}

.space-between {
  justify-content: space-between;
}

.delivery-type {
  color: #424242;
  padding-top: 20px;
  padding-bottom: 20px;
}

.border-bt {
  border-bottom: 0.7px solid #bfbfbf;
}

.info-delivery__title {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

.info-delivery__sub-title {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-style: italic;
}

.check-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.check-box>img {
  width: 22px;
  height: 22px;
}

.b2c-pc-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid #dadada;
  width: 100%;
}

.b2c-pc-header {
  display: flex;
}

.b2c-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
  width: 50%;
}

.b2c-pc-payment {
  margin-top: 50px;
}

.b2c-pc-payment h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: #424242;
  text-transform: uppercase;
}

.b2c-pc-pack-content .cart {
  width: 100%;
  background: #fafafa;
  border: 1.5px solid #d2d2d2;
  border-radius: 10px;
  padding: 22px 25px;
  height: calc(188.54px + 44px);
  position: relative;
  margin-bottom: 17px;
}

.b2c-pc-pack-content .cart .image {
  width: 154px;
  height: 188.54px;
  background: #ffffff;
  box-shadow: 1px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  float: left;
}

.b2c-pc-pack-content .cart .info {
  float: right;
  width: calc(100% - 184px);
}

.b2c-pc-pack-content .cart .info h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-right: 5px;
}

.b2c-pc-pack-content .cart .info .price {
  font-weight: 900;
  font-size: 24px;
  line-height: 15px;
  color: #e9182c;
}

.b2c-pc-pack-content .cart .info .price_box {
  float: right;
  text-align: right;
  padding: 10px 10px 0 0;
}

.b2c-pc-pack-content .cart .info .base_price {
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #525252;
  line-height: 2;
  text-decoration: line-through;
}

.b2c-pc-pack-content .cart .info .quantity p {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.7;
  padding-bottom: 6px;
}

.b2c-pc-pack-content .cart .info .quantity {
  position: absolute;
  bottom: 22px;
}

.b2c-pc-pack-content .cart .info .amount {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  opacity: 0.8;
  padding: 0px 10px;
  display: inline;
}

.b2c-pc-pack-content .cart .info .quantity svg {
  margin-bottom: 7px;
}

.b2c-pc-pack-content .cart .remove {
  font-weight: 400;
  font-size: 15px;
  line-height: 111.69%;
  text-decoration-line: underline;
  color: #e9182c;
  position: absolute;
  right: 25px;
  bottom: 22px;
}

.b2c-pc-pack-content .cart .features {
  margin-top: 10px;
}

.b2c-pc-pack-content .cart .features span,
.b2c-pc-pack-content .cart .features div {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: block !important;
}

.b2c-pc-pack-content .cart .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 11px;
}

.features .variant-color {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #666;
}

.features .color-text {
  padding-top: 4px;
  padding-right: 6px;
}

.result-phone {
  max-width: 321px;
  text-align: center;
  margin: 0 auto;
}

.complete .card-body {
  padding: 0 !important;
}

.huge-text {
  font-size: 24px;
  font-weight: 700;
}

.btn-back {
  font-size: 18px !important
}

@media screen and (min-width: 451px) {
  .auth-inner {
    min-width: 450px;
  }
}

.card {
  padding: 27px;
  -webkit-border-radius: 1rem !important;
  border-radius: 1rem !important;
  margin: 0 auto;
  max-width: 562px;
  /* max-height: 1087px; */
}

.detail-order {
  max-width: 325px;
  max-height: 315px;
  margin: 0 auto;
  border: #ffff;
  border-radius: 8px;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.05);
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.error {
  border: 1px solid red !important;
}

.item-single-info {
  display: flex;
  margin: 5px 0;
}

.item-single-info__title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.7;
}

.item-single-info__name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.9;
  margin-left: 5px;
}

.item-single-info__quantity {
  display: flex;
}

.inputText {
  font-size: 18px;
}

.paymentBox {
  height: 52px;
  margin-bottom: 4%;

}

.custom-checkbox .custom-control-label::before {
  border-radius: 50%;
}

.buttonZaloMomo {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  ;
  border-radius: 8px;
  margin: 2%;
  width: 231px;
  height: 80px
}

.borderPaymentBox {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.buttonZaloMomoActive {
  border: 1px solid #FF0000;
}

[dir] .custom-checkbox .custom-control-input:disabled~.custom-control-label::before {
  background-color: white;
  border: #d8d6de solid 1px;
}

[dir] .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgb(255 0 6);
}

.text-middle {
  vertical-align: middle;
}

.lbl-text-empty {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}

.cart-empty-help-lbl {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #424242;
  display: inline-block;
}


.cart-empty-help-lbl .phone-number {
  font-weight: bold;
  color: red;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  display: inline-block;
}

.img-emtpty {
  padding-top: 8%;
}

@media screen and (max-width: 450px) {
  .img-emtpty {
    padding-top: 4%;
  }

  .btn-back {
    font-size: 16px !important;
  }

  .buttonZaloMomo {
    width: 45%;
  }

  .zaloLogo {
    width: 85%;
    height: 45%;
  }

  .inputText {
    font-size: 16px;
  }
}

#modal-whole-sale .modal-title {
  text-align: center !important;
  font-weight: bold !important;
  width: 100%;
}
</style>
